/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function SupportedServicesMSM() {
  const [companyContentData, setCompanyContentData] = useState([]);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "supp_service" || item.contentType === "supp_service_title";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "supp_service_title")
          .map((contentItem, index) => {
            return (
              <div>
                <MKTypography variant="h3" fontWeight="bold">{contentItem.contentTitle}</MKTypography>
                <MKTypography variant="body1" color="text">{contentItem.contentDesc}</MKTypography>
              </div>
            )
          })}
        </Grid>
        <Grid container spacing={3} alignItems="center">
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "supp_service")
          .map((contentItem, index) => {
            return (
              <Grid item xs={12} lg={4} sx={{ ml:"auto", mt: { xs: 3, lg: 0 } }}>
                <CenteredBlogCard
                  key={index}
                  image={`data:image/jpeg;base64,${contentItem.contentImage}`}
                  title={contentItem.contentTitle}
                  description={contentItem.contentDesc}
                  action={{
                    type: "internal"
                  }}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SupportedServicesMSM;
