/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React components
import MKBox from "components/MKBox";

function HomeImageBackground({ imageUrl }) {
  return (
    <MKBox
      minHeight="70vh"
      width="100%"
      borderRadius="10px"
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.success.main, 0.05),
            rgba(gradients.success.state, 0.05)
          )}, url(${imageUrl})`, 
        backgroundSize: "cover",
        backgroundPosition: "center ",
        display: "grid",
        placeItems: "center",
      }}
    />
  );
}

export default HomeImageBackground;
