/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Images
import paket from "assets/images/pengiriman-paket-edit.jpg"

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function MainServiceCardMSM() {
  const [companyContentData, setCompanyContentData] = useState([]);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "main_service_1" || item.contentType === "main_service_2" || item.contentType === "main_service_3";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <MKBox
        display="flex"
        alignItems="center"
        borderRadius="xl"
        my={2}
        py={6}
        mt={15}
        mx={10}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${paket})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
            <MKTypography variant="h4" color="white" fontWeight="bold">
              Kami menyediakan
            </MKTypography>
            <MKTypography variant="h1" color="white" mb={1}>
              {companyContentData.find((contentItem) => contentItem.contentType === "main_service_1")?.contentTitle}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
              {companyContentData.find((contentItem) => contentItem.contentType === "main_service_1")?.contentDesc}
            </MKTypography>
            <MKTypography
              component="a"
              href={companyContentData.find((contentItem) => contentItem.contentType === "main_service_1")?.others}
              target="_blank"
              rel="noreferrer"
              variant="body2"
              color="white"
              fontWeight="regular"
              sx={{
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              Pesan Sekarang <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <Grid container spacing={3} mt={-1}>
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "main_service_2")
          .map((contentItem, index) => (
            <Grid item xs={12} lg={4} key={index}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon={contentItem.others}
                title={contentItem.contentTitle}
                description={contentItem.contentDesc}
              />
            </Grid>
          ))}
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "main_service_3")
          .map((contentItem, index) => (
            <Grid item xs={12} lg={4} key={index}>
              <FilledInfoCard
                color="info"
                icon={contentItem.others}
                title={contentItem.contentTitle}
                description={contentItem.contentDesc}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default MainServiceCardMSM;
