/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBadge from "components/MKBadge";
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";

// Images
import logo from "assets/images/r-logo.png";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function ProfileMSM() {
  const [companyContentData, setCompanyContentData] = useState([]);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "career_office";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MKBox component="section" >
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          {companyContentData
            .map((contentItem, index) => {
              return (
                <MKBox mt={{ xs: -9, md: -9 }} textAlign="center">
                  <MKAvatar src={`data:image/jpeg;base64,${contentItem.contentImage}`} alt={contentItem.contentTitle} size="xxl" shadow="xl" />
                  <MKBadge
                      variant="contained"
                      color={contentItem.contentDesc}
                      badgeContent={contentItem.contentTitle}
                      container
                      sx={{ mt:5, ml:-4 }}
                    />
                </MKBox>
              )
          })}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ProfileMSM;
