/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import QuoteIcon from '@mui/icons-material/FormatQuote';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

function CenteredBackgroundBlogCard({ image, title, label, type }) {
  const cardActionStyles = {
    display: "flex",
    alignItems: "center",
    width: "max-content",

    "& .material-icons, .material-icons-round,": {
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
      {
        transform: `translateX(6px)`,
      },
  };

  return (
    <div>
      {(type === "filled") ? (
        <Card 
          sx={{
            backgroundImage: ({ palette: { black }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(rgba(black.main, 0.5), rgba(black.main, 0.5))}, url(${image})`,
            backgroundSize: "cover",
          }}
        >
          <MKBox p={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <QuoteIcon color="white" fontSize="large" />
            <MKBox minHeight="10.625rem" my="auto" py={3} px={3} >
              <MKTypography
                variant="h4"
                color="white"
                mb={1}
                sx={({
                  fontSize: '1.5rem',
                  fontStyle: 'italic',
                  fontWeight: 'normal',
                  textAlign: 'center',
                })}
              >
                {title}
              </MKTypography>
            </MKBox>
            <MKBadge
              variant="gradient"
              color="light"
              size="md"
              badgeContent={label}
              container
              sx={{ mb: 2 }}
            />
          </MKBox>
        </Card>
      ) : (
        <Card sx={{ border: '2px dashed  darkblue' }}>
          <MKBox p={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <QuoteIcon color="dark" fontSize="large" />
            <MKBox minHeight="10.625rem" my="auto" py={3} px={3} >
              <MKTypography
                variant="h4"
                color="dark"
                mb={1}
                sx={({
                  fontSize: '1.5rem',
                  fontStyle: 'italic',
                  fontWeight: 'normal',
                  textAlign: 'center',
                })}
              >
                {title}
              </MKTypography>
            </MKBox>
            <MKBadge
              variant="contained"
              color="info"
              size="md"
              badgeContent={label}
              container
              sx={{ mb: 2 }}
            />
          </MKBox>
        </Card>
      )}
    </div>
    
  );
}

// Typechecking props for the BackgroundBlogCard
CenteredBackgroundBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['filled', 'not-filled']).isRequired,
};

export default CenteredBackgroundBlogCard;
