/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Fetching Data - Web Components
import { useState } from "react";
import { API_SERVER } from 'config/constant';

function ContactFormMSM({ updateSubmissionStatus }) {
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    company: "",
    title: "",
    message: ""
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Gabungkan data First Name dan Last Name menjadi satu dalam field Name
    const fullName = `${formData.firstName} ${formData.lastName}`;

    // Prepare the data to be sent in the POST request
    const postData = {
      name: fullName,
      email: formData.email,
      phoneNum: formData.phone,
      city: formData.city,
      company: formData.company,
      title: formData.title,
      message: formData.message
    };

    // Perform the POST request to the /api/cstickets endpoint
    fetch(`${API_SERVER}cstickets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
    
      .then((response) => {
        if (response.status === 200) {
          // Handle success, e.g., show a success message
          updateSubmissionStatus('success'); // Call the callback function to update submissionStatus
          setFormData(initialFormData);
        } else {
          // Handle error, e.g., show an error message
          updateSubmissionStatus('error'); // Call the callback function to update submissionStatus
        }
        setTimeout(() => {
          updateSubmissionStatus(null); // Reset submissionStatus
        }, 5000);
      })
      .catch((error) => {
        // Handle network errors, etc.
        updateSubmissionStatus('error'); // Call the callback function to update submissionStatus

        setTimeout(() => {
          updateSubmissionStatus(null); // Reset submissionStatus
        }, 5000);
      });
  };

  return (
    <MKBox width="100%" component="form" method="post" autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <MKInput
            variant="standard"
            label="First Name"
            placeholder="Nama Depan"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MKInput
            variant="standard"
            label="Last Name"
            placeholder="Nama Belakang"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MKInput
            type="email"
            variant="standard"
            label="Email"
            placeholder="Email"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MKInput
            variant="standard"
            label="Phone Number"
            placeholder="Nomor WhatsApp"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MKInput
            variant="standard"
            label="City"
            placeholder="Asal Kota"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MKInput
            variant="standard"
            label="Company/Institution"
            placeholder="Perusahaan/Institusi"
            InputLabelProps={{ shrink: true }}
            fullWidth
            name="company"
            value={formData.company}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <MKInput
            variant="standard"
            label="Title"
            placeholder="Judul pengaduan Anda"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <MKInput
            variant="standard"
            label="What can we help you?"
            placeholder="Deskripsikan komentar Anda dalam 250 kata"
            InputLabelProps={{ shrink: true }}
            multiline
            fullWidth
            rows={6}
            required
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
        <MKButton type="submit" variant="gradient" color="success">
          Send Message
        </MKButton>
      </Grid>
    </MKBox>
  );
}

export default ContactFormMSM;