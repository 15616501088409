/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Products page sections
import CategoryList from "./CategoryList";

// Fetching Data - Web Components
import { useState, useEffect } from "react";

function MajorCategoryList({ majorCategories, onSelectedCategoriesChange }) {
  const [selectedMajorCategory, setSelectedMajorCategory] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleMajorCategoryClick = (majorCategory) => {
    if (selectedMajorCategory === majorCategory) {
      // If the same category is clicked again, close it
      setSelectedMajorCategory(null);
    } else {
      setSelectedMajorCategory(majorCategory);
    }
  };

  useEffect(() => {
    onSelectedCategoriesChange(selectedCategories);
  }, [selectedCategories, onSelectedCategoriesChange]);

  return (
    <div>
      <List>
        {majorCategories.map((majorCategory, index) => (
          <div key={index}>
            <ListItemButton onClick={() => handleMajorCategoryClick(majorCategory)}>
              <MKTypography variant="h6">{majorCategory}</MKTypography>
            </ListItemButton>
            {selectedMajorCategory === majorCategory && (
              <CategoryList
                majorCategory={selectedMajorCategory}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            )}
          </div>
        ))}
      </List>
    </div>
  );
}

export default MajorCategoryList;
