/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Teams page sections
import ProfileMSM from "./sections/Profile";
import TeamMSM from "./sections/Team";
import VacancyMSM from "./sections/Vacancy";
import ImageBackground from "./sections/TeamImage";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/office-edit2.png";

// Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel"; 

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function TeamsMSM() {
  const [companyContentData, setCompanyContentData] = useState([]);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "team_pictures" ||  item.contentType === "career_title" ||  item.contentType === "about_us_office";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex={2}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://wa.me/6282348661955",
            label: "Contact Now!",
            color: "success",
          }}
          sticky
        />
      </MKBox>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${`data:image/jpeg;base64,${companyContentData.find((contentItem) => contentItem.contentType === "about_us_office")?.contentImage}`})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 0,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <ProfileMSM />
          <Grid container>
            {companyContentData
            .filter((contentItem) => contentItem.contentType === "career_title")
            .map((contentItem, index) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  lg={6}
                  flexDirection="column"
                  alignItems="center"
                  sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
                >
                  <MKTypography variant="h2" fontWeight="bold">{contentItem.contentTitle}</MKTypography>
                  <MKTypography variant="body1" color="text">{contentItem.contentDesc}</MKTypography>
                </Grid>
                )
            })}
            <Grid item xs={12} lg={10} p={2} ml={{ xs: "auto", lg: 15 }} >
              {companyContentData.filter((contentItem) => contentItem.contentType === "team_pictures").length > 0 && (
                <Carousel
                  showArrows={true}
                  showStatus={false}
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  dynamicHeight={false}
                  interval={3000}
                  stopOnHover={true}
                >
                  {companyContentData
                    .filter((contentItem) => contentItem.contentType === "team_pictures")
                    .map((contentItem, index) => {
                      return (
                        <ImageBackground imageUrl={`data:image/jpeg;base64,${contentItem.contentImage}`} />
                      )
                  })}
                  {/* Add more images here */}
                </Carousel>
              )}
            </Grid>
          </Grid>
          <TeamMSM />
          <VacancyMSM />
        </Card>
        <MKBox pt={6} px={1} mt={0}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default TeamsMSM;
