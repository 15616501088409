/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { InputLabel } from '@mui/material';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';
import MKInput from 'components/MKInput';
import MKTypography from 'components/MKTypography';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import ImageUploader from './imageUploader';

// Fetching Data - Web Components
import { useAuth } from "auth-context/auth.context";
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from "axios";
// Images
import def_image from "assets/images/no-img.jpg";

function UpdateModalMSM({ isOpen, closeModal, title, data, columns, isImage, image, table, setSubmissionStatus }) {
  for (const key in data) {
    if (data[key] === "-") {
      data[key] = null;
    } else if (data[key] === "Tampilkan") {
      data[key] = true;
    } else if (data[key] === "Sembunyikan") {
      data[key] = false;
    }
  }

  const [formData, setFormData] = useState(data); // Inisialisasi formData dengan data yang telah ada
  const updateSubmissionStatus = (status) => {
    setSubmissionStatus(status);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let convert_image = def_image;
  if (image && image !== "-") {
    convert_image = `data:image/jpeg;base64,${image}`;
  }

  const handleImageChange = (e, key) => {
    console.log("MASUK HANDLE IMAGE CHANGE")
    const file = e;
  
    if (file) {
      const reader = new FileReader();
  
      reader.onload = function () {
        const blobImage = new Blob([new Uint8Array(reader.result)]);
  
        const readerBase64 = new FileReader();
        readerBase64.onload = function () {
          const base64Data = readerBase64.result.split(',')[1];
  
          // Dapatkan salinan objek formData saat ini
          const updatedFormData = { ...formData };
  
          // Menambahkan base64 data blob ke formData dengan nama key yang sesuai
          updatedFormData[key] = base64Data;
  
          // Update state formData dengan objek yang baru
          setFormData(updatedFormData);
        };
  
        readerBase64.readAsDataURL(blobImage);
      };
  
      reader.readAsArrayBuffer(file);
    } else {
      // Jika tidak ada file yang diunggah, atur formData ke null
      const updatedFormData = { ...formData };
      updatedFormData[key] = null;
      setFormData(updatedFormData);
    }
  };
  

  const handleInputChange = (e, key) => {
    // Ambil nilai dari input box
    const value = e.target.value;
    // Dapatkan salinan objek formData saat ini
    const updatedFormData = { ...formData };
    // Cek tipe data input, jika input berasal dari input box dengan tipe number
    // maka konversi ke tipe data number
    if (e.target.type === "number") {
      updatedFormData[key] = parseFloat(value); // Konversi ke tipe data number
    } else {
      updatedFormData[key] = value; // Biarkan tipe data seperti aslinya
    }
    // Update state formData dengan objek yang baru
    setFormData(updatedFormData);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let endpoint = '';
    if (table === "job_vacancy") {
      endpoint = "careers/vacancy"
    } else if (table === "faq") {
        endpoint = "faq";
    } else if (table === "team_position") {
      endpoint = "team/positions"
    } else if (table === "products") {
      endpoint = "products/all"
    } else if (table === "product_category") {
      endpoint = "products/category"
    } else if (table === "product_details") {
      endpoint = "products/details"
    } else if (table === "product_master") {
      endpoint = "products/master"
    } else if (table === "product_vendor") {
      endpoint = "products/vendor"
    } else if (table === "company_content") {
      endpoint = "company/content";
    } else if (table === "company_info") {
      endpoint = "company/info";
    }
    
    const api_url = `${API_SERVER}${endpoint}/${formData.id}`;

    // Menghapus atribut dengan nilai null dari formData
    for (const key in formData) {
      if (formData[key] === null) {
        delete formData[key];
      }
    }
    // Membuat objek konfigurasi untuk permintaan
    const requestOptions = {
      method: 'PUT', // Ganti dengan metode yang sesuai, misalnya 'POST' jika perlu
      headers: {
        'Content-Type': 'application/json', // Sesuaikan dengan tipe data yang dikirimkan
      },
      body: JSON.stringify(formData), // Mengubah formData ke dalam format JSON jika diperlukan
    };
    console.log(formData);

    // Melakukan permintaan API dengan fetch
    fetch(api_url, requestOptions)
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        updateSubmissionStatus('success'); // Menggunakan fungsi updateSubmissionStatus
        setTimeout(() => {
          updateSubmissionStatus(null); // Reset submissionStatus
        }, 3000);
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        // Tangani kesalahan jika ada
        updateSubmissionStatus('error'); // Menggunakan fungsi updateSubmissionStatus
        setTimeout(() => {
          updateSubmissionStatus(null); // Reset submissionStatus
        }, 3000);
      });
  };
  
  // console.log(formData);
  // Kemudian Anda dapat memanggil handleFormSubmit() saat tombol "Update" ditekan.
  return (
    <Modal open={isOpen} onClose={closeModal} sx={{ display: 'grid', placeItems: 'center' }}>
      <Slide direction="down" in={isOpen} timeout={500}>
        <MKBox
          position="relative"
          width="800px"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          bgColor="white"
          shadow="xl"
        >
          <div style={{ overflowY: 'auto', maxHeight: '80vh' }}>
          <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
            <MKTypography variant="h5">
              {title} dengan ID {`${data.id}`}
            </MKTypography>
            <CloseIcon fontSize="medium" sx={{ cursor: 'pointer' }} onClick={closeModal} />
          </MKBox>
          <Divider sx={{ my: 0 }} />
          {isImage ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <MKBox
                  component="img"
                  src={convert_image}
                  borderRadius="lg"
                  width="100%"
                  position="stick"
                  zIndex={1}
                  sx={{ ml: 3 }}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <MKBox p={1} ml={2}>
                  {columns.length > 0 && (
                    <MKBox width="100%" component="form" autoComplete="off" onSubmit={handleFormSubmit}>
                      {Object.keys(formData).map((key) => {
                        if (key.includes("Image")) {
                          return (
                            <Grid item xs={12} md={12} sx={{ ml:5, mr:5 }}>
                              <InputLabel sx={{ mb: 1, mt: 2 }}>{key}</InputLabel>
                              <ImageUploader
                                key={key}
                                accept=".jpeg,.jpg,.png"
                                onChange={(e) => handleImageChange(e, key)}
                              />
                            </Grid>
                          );
                        } else if ( key === "id") {
                          return (
                            <Grid item xs={12} md={12} sx={{ ml:5, mr:5 }}>
                            </Grid>
                          );
                        } else if ( key === "status") {
                          return (
                            <Grid item xs={12} md={12} sx={{ ml:5, mr:5 }}>
                              <InputLabel sx={{ mb: 1, mt: 2 }}>{key} *</InputLabel>
                              <Select
                                key={key}
                                variant="standard"
                                label={key}
                                sx={{ mb: 2 }}
                                value={formData[key]}
                                placeholder={formData[key]}
                                onChange={(e) => handleInputChange(e, key)}
                                fullWidth
                                required 
                              >
                                <MenuItem value={true}><MKTypography variant="body2">Tampilkan</MKTypography></MenuItem>
                                <MenuItem value={false}><MKTypography variant="body2">Sembunyikan</MKTypography></MenuItem>
                              </Select>
                            </Grid>
                          );
                        } else if ( key.includes("id") || key.includes("contentType") ) {
                          return (
                            <Grid item xs={12} md={12} sx={{ ml:5, mr:5 }}>
                              <InputLabel sx={{ mb: 1, mt: 2 }}>{key}</InputLabel>
                              <MKInput
                                key={key}
                                variant="standard"
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder={formData[key]}
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                                disabled
                                multiline
                              />
                            </Grid>
                          );
                        } else if ( key === "others") {
                          return (
                            <Grid item xs={12} md={12} sx={{ ml:5, mr:5 }}>
                              <InputLabel sx={{ mb: 1, mt: 2 }}>{key}</InputLabel>
                              <MKInput
                                key={key}
                                variant="standard"
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder={formData[key]}
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                                multiline
                              />
                            </Grid>
                          );
                        } else {
                          return (
                            <Grid item xs={12} md={12} key={key} sx={{ ml:5, mr:5 }}>
                              <InputLabel sx={{ mb: 1, mt: 2 }}>{key} *</InputLabel>
                              <MKInput
                                key={key}
                                variant="standard"
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder={formData[key]}
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                                required
                                multiline
                              />
                            </Grid>
                          );
                        }
                      })}
                      <Grid container item xs={12} my={2} sx={{ px:5 }} justifyContent="center">
                        <MKButton type="submit" variant="gradient" color="success">
                          Update
                        </MKButton>
                      </Grid>
                    </MKBox>
                  )}
                </MKBox>
              </Grid>
            </Grid>
          ) : (
            // Render form dengan input fields yang bisa diubah
              columns.length > 0 && (
                <MKBox width="100%" component="form" autoComplete="off" onSubmit={handleFormSubmit}>
                  {Object.keys(formData).map((key) => {
                    if ( key === "id") {
                      return (
                        <Grid item xs={12} md={12} sx={{ ml:5, mr:5 }}>
                        </Grid>
                      );
                    } else if ( key.includes("id") || key.includes("contentType") ) {
                      return (
                        <Grid item xs={12} md={12} sx={{ ml:5, mr:5 }}>
                          <InputLabel sx={{ mb: 1, mt: 2 }}>{key}</InputLabel>
                          <MKInput
                            key={key}
                            variant="standard"
                            fullWidth
                            sx={{ mb: 2 }}
                            placeholder={formData[key]}
                            name={key}
                            value={formData[key]}
                            onChange={handleChange}
                            disabled
                            multiline
                          />
                        </Grid>
                      );
                    } else if ( key === "status") {
                      return (
                        <Grid item xs={12} md={12} sx={{ ml:5, mr:5 }}>
                          <InputLabel sx={{ mb: 1, mt: 2 }}>{key} *</InputLabel>
                          <Select
                            key={key}
                            variant="standard"
                            label={key}
                            sx={{ mb: 2 }}
                            value={formData[key]}
                            placeholder={formData[key]}
                            onChange={(e) => handleInputChange(e, key)}
                            fullWidth
                            required 
                          >
                            <MenuItem value={true}><MKTypography variant="body2">Tampilkan</MKTypography></MenuItem>
                            <MenuItem value={false}><MKTypography variant="body2">Sembunyikan</MKTypography></MenuItem>
                          </Select>
                        </Grid>
                      );
                    } else if ( key === "others") {
                      return (
                        <Grid item xs={12} md={12} sx={{ ml:5, mr:5 }}>
                          <InputLabel sx={{ mb: 1, mt: 2 }}>{key}</InputLabel>
                          <MKInput
                            key={key}
                            variant="standard"
                            fullWidth
                            sx={{ mb: 2 }}
                            placeholder={formData[key]}
                            name={key}
                            value={formData[key]}
                            onChange={handleChange}
                            multiline
                          />
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={12} md={12} key={key} sx={{ ml:5, mr:5 }}>
                          <InputLabel sx={{ mb: 1, mt: 2 }}>{key} *</InputLabel>
                          <MKInput
                            key={key}
                            variant="standard"
                            fullWidth
                            sx={{ mb: 2 }}
                            placeholder={formData[key]}
                            name={key}
                            value={formData[key]}
                            onChange={handleChange}
                            required
                            multiline
                          />
                        </Grid>
                      );
                    }
                  })}
                  <Grid container item xs={12} my={2} sx={{ px:5 }} justifyContent="center">
                    <MKButton type="submit" variant="gradient" color="success">
                      Update
                    </MKButton>
                  </Grid>
                </MKBox>
              )
          )}
          <Divider sx={{ my: 0 }} />
          <MKBox display="flex" justifyContent="space-between" p={1.5}>
            <div onClick={closeModal} style={{ cursor: 'pointer' }}>
              <MKTypography variant="button" fontWeight="regular" color="dark" textTransform="capitalize" ml={1.5}>
                Close
              </MKTypography>
            </div>
          </MKBox>
          </div>
        </MKBox>
      </Slide>
    </Modal>
  );
}

export default UpdateModalMSM;