/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Card, InputLabel } from '@mui/material';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Material Kit 2 React components
import MKAlert from 'components/MKAlert';
import MKBox from "components/MKBox";
import MKButton from 'components/MKButton';
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import adminRoutes from 'adminRoutes';
import footerRoutes from "footer.routes";

// Fetching Data - Web Components
import { useAuth } from "auth-context/auth.context";
import { useState } from "react";
import { API_SERVER } from 'config/constant';
import Axios from "axios";

// Add Data page components
import ImageUploader from 'company-admin-pages/ModalComponents/imageUploader';

function AddDataMSM() {
  const { user } = useAuth();
  const [selectedTable, setSelectedTable] = useState('');
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(null);

  // Fungsi untuk mengambil data dari tabel yang dipilih
  const fetchDataFromTable = (tableName) => {
    if (tableName === "job_vacancy") {
      tableName = "careers/vacancy"
    } else if (tableName === "team_position") {
      tableName = "team/positions"
    } else if (tableName === "product_category") {
      tableName = "products/category"
    } else if (tableName === "product_details") {
      tableName = "products/details"
    } else if (tableName === "product_master") {
      tableName = "products/master"
    } else if (tableName === "product_vendor") {
      tableName = "products/vendor"
    } else if (tableName === "company_content") {
      tableName = "company/content"
    }
    // Ganti 'URL_API' dengan URL API yang sesuai untuk mengambil data dari tabel yang dipilih
    Axios.get(`${API_SERVER}${tableName}`)
      .then((response) => {
        const data = response.data;
        // Simpan data yang diambil ke dalam state 'tableData'
        setTableData(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  // Fungsi untuk menangani perubahan saat tabel dipilih
  const handleTableSelectChange = (e) => {
    const selectedTableName = e.target.value;
    setSelectedTable(selectedTableName);
    // Reset formData menjadi objek kosong saat tabel berubah
    setFormData({});
    // Panggil fungsi fetchDataFromTable dengan nama tabel yang dipilih
    fetchDataFromTable(selectedTableName);
  };

  const handleInputChange = (e, key) => {
    // Ambil nilai dari input box
    const value = e.target.value;
    // Dapatkan salinan objek formData saat ini
    const updatedFormData = { ...formData };
    // Cek tipe data input, jika input berasal dari input box dengan tipe number
    // maka konversi ke tipe data number
    if (e.target.type === "number") {
      updatedFormData[key] = parseFloat(value); // Konversi ke tipe data number
    } else {
      updatedFormData[key] = value; // Biarkan tipe data seperti aslinya
    }
    // Update state formData dengan objek yang baru
    setFormData(updatedFormData);
  };

  function blobToBase64(blob, callback) {
    const reader = new FileReader();
    reader.onload = function () {
      const base64data = reader.result.split(',')[1];
      callback(base64data);
    };
    reader.readAsDataURL(blob);
  }

  const handleImageChange = (e, key) => {
    const file = e;
  
    if (file) {
      const reader = new FileReader();
  
      reader.onload = function () {
        const blobImage = new Blob([new Uint8Array(reader.result)]);
  
        const readerBase64 = new FileReader();
        readerBase64.onload = function () {
          const base64Data = readerBase64.result.split(',')[1];
  
          // Dapatkan salinan objek formData saat ini
          const updatedFormData = { ...formData };
  
          // Menambahkan base64 data blob ke formData dengan nama key yang sesuai
          updatedFormData[key] = base64Data;
  
          // Update state formData dengan objek yang baru
          setFormData(updatedFormData);
        };
  
        readerBase64.readAsDataURL(blobImage);
      };
  
      reader.readAsArrayBuffer(file);
    }
  };
  

  // Fungsi untuk menangani penambahan data baru
  const handleAddNewData = (event) => {
    event.preventDefault();

    // Tentukan endpoint API berdasarkan jenis tabel yang dipilih
    let path_lanjutan = '';
    if (selectedTable === "job_vacancy") {
      path_lanjutan = "careers/vacancy"
    } else if (selectedTable === "team_position") {
      path_lanjutan = "team/positions"
    } else if (selectedTable === "product_category") {
      path_lanjutan = "products/category"
    } else if (selectedTable === "product_details") {
      path_lanjutan = "products/details"
    } else if (selectedTable === "product_master") {
      path_lanjutan = "products/master"
    } else if (selectedTable === "product_vendor") {
      path_lanjutan = "products/vendor"
    } else if (selectedTable === "faq") {
      path_lanjutan = "faq";
    } else if (selectedTable === "company_content") {
      path_lanjutan = "company/content";
    }
  
    const apiUrl = `${API_SERVER}${path_lanjutan}`;
    // Konfigurasi untuk permintaan POST
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    Axios.post(apiUrl, formData, config)
      .then(response => {
        setSelectedTable('');
        setFormData({});
        setTableData([]);
        setSubmissionStatus('success');
        setTimeout(() => {
          setSubmissionStatus(null); // Reset submissionStatus
        }, 3000);
        console.log('Berhasil menambahkan data:', response.data);
      })
      .catch(error => {
        setSubmissionStatus('error');
        setTimeout(() => {
          setSubmissionStatus(null); // Reset submissionStatus
        }, 3000);
        console.error('Gagal menambahkan data:', error);
      });
  };  

  console.log(formData);
  
  
  return (
    <>
      {user && user.token ? (
        <DefaultNavbar
          routes={adminRoutes}
          action={{
            type: "internal",
            route: "/admin-multi-sinar/sign-out",
            label: "logout",
            color: "error",
          }}
          sticky
        />
      ) : (
        <DefaultNavbar
        routes={adminRoutes}
          action={{
            type: "external",
            route: "/",
            label: "Website",
            color: "info",
          }}
          sticky
        />
      )}
      <Card
        sx={{
          p: 2,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          position="fixed"
          top="5.5rem"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="9999"
        >
          {submissionStatus === 'success' && (
            <MKAlert color="success" dismissible>Data Added!</MKAlert>
          )}
          {submissionStatus === 'error' && (
            <MKAlert color="error" dismissible>There's an error occur!</MKAlert>
          )}
        </MKBox>
        <Container sx={{ width: '100%', mb: 2, mt: 17 }}>
          <Grid container item justifyContent="center" xs={10} lg={8} mx="auto" textAlign="center">
            <MKTypography variant="h3" mb={1}>
              Add New Data Page
            </MKTypography>
          </Grid>
          <Grid container item xs={12} lg={10} sx={{ mx: "auto" }}>
            <MKBox width="100%" p={3}>
              <MKBox width="100%">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} ml={-1.5}>
                    <InputLabel sx={{ mb:1 }}>Select Table</InputLabel>
                    <Select
                      variant="standard"
                      fullWidth
                      value={selectedTable}
                      onChange={handleTableSelectChange}
                      displayEmpty 
                      sx={{ mb:5 }}
                    >
                      <MenuItem value="" disabled><MKTypography variant="body2">Select Table</MKTypography></MenuItem>
                      <MenuItem value="faq"><MKTypography variant="body2">FAQ Page</MKTypography></MenuItem>  
                      <MenuItem value="job_vacancy"><MKTypography variant="body2">Job Vacancy</MKTypography></MenuItem> 
                      <MenuItem value="team_position"><MKTypography variant="body2">Team Position</MKTypography></MenuItem>
                      <MenuItem value="company_content"><MKTypography variant="body2">Company Content</MKTypography></MenuItem> 
                      <MenuItem value="product_category"><MKTypography variant="body2">Product Category</MKTypography></MenuItem>
                      <MenuItem value="product_details"><MKTypography variant="body2">Product Details</MKTypography></MenuItem> 
                      <MenuItem value="product_master"><MKTypography variant="body2">Product Master</MKTypography></MenuItem> 
                      <MenuItem value="product_vendor"><MKTypography variant="body2">Product Vendor</MKTypography></MenuItem> 
                    </Select>
                  </Grid>
                  {tableData.length > 0 && (
                    <MKBox width="100%" component="form" autoComplete="off" onSubmit={handleAddNewData}>
                      {Object.keys(tableData[0]).map((key) => {
                        const placeholder = key.startsWith("id") ? `ID Terakhir adalah ${tableData[tableData.length - 1][key]}` : tableData[tableData.length - 1][key];
                        if (key.includes("Image")) {
                          // Jika key mengandung kata "image", tampilkan komponen InputUploader dengan filter untuk JPG/PNG
                          return (
                            <Grid item xs={12} md={12}>
                              <InputLabel sx={{ mb:1, mt: 2 }}>{key}</InputLabel>
                              <ImageUploader
                                key={key}
                                accept=".jpeg,.jpg,.png"
                                onChange={(e) => handleImageChange(e, key)}
                              />
                            </Grid>
                          );
                        } else if (key === "status") {
                          // Jika key adalah "status", tampilkan komponen Select
                          return (
                            <Grid item xs={12} md={12}>
                              <InputLabel sx={{ mb:1, mt: 3 }}>{key}</InputLabel>
                              <Select
                                key={key}
                                variant="standard"
                                label={key}
                                sx={{ mb: 2 }}
                                placeholder={placeholder}
                                onChange={(e) => handleInputChange(e, key)}
                                fullWidth
                                required 
                              >
                                <MenuItem value={true}><MKTypography variant="body2">Tampilkan</MKTypography></MenuItem>
                                <MenuItem value={false}><MKTypography variant="body2">Sembunyikan</MKTypography></MenuItem>
                              </Select>
                            </Grid>
                          );
                        } else if (key === "idPosition" || key === "idContent" || key === "others") {
                          return (
                            <Grid item xs={12} md={12}>
                            </Grid>
                          );
                        } else if (key === "productQtyInUnit" || key === "productPrice" || key === "stock" ) {
                          return (
                            <Grid item xs={12} md={12}>
                              <MKInput
                                key={key}
                                variant="standard"
                                type="number"
                                label={key.startsWith("id") ? `${key} - ${tableData[tableData.length - 1][key]}` : key}
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder={placeholder}
                                onChange={(e) => handleInputChange(e, key)}
                                required
                              />
                            </Grid>
                          );
                        } else if (key.includes("date") || key.includes("Date") || key.includes("DATE")) {
                          return (
                            <Grid item xs={12} md={12}>
                              <InputLabel sx={{ mb:1, mt: 3 }}>{key.startsWith("id") ? `${key} - ${tableData[tableData.length - 1][key]}` : key}</InputLabel>
                              <MKInput
                                key={key}
                                variant="standard"
                                type="date"
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder={placeholder}
                                onChange={(e) => handleInputChange(e, key)}
                                required
                              />
                            </Grid>
                          );
                        } else {
                          // Jika bukan "status", tampilkan komponen MKInput
                          return (
                            <Grid item xs={12} md={12}>
                              <MKInput
                                key={key}
                                variant="standard"
                                label={key.startsWith("id") ? `${key} - ${tableData[tableData.length - 1][key]}` : key}
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder={placeholder}
                                onChange={(e) => handleInputChange(e, key)}
                                required
                                multiline
                              />
                            </Grid>
                          );
                        }
                      })}
                      <Grid container item xs={12} my={2} sx={{ mt:5 }}>
                        <MKButton type="submit" variant="gradient" color="info" fullWidth>
                          Add New Data
                        </MKButton>
                      </Grid>
                    </MKBox>
                  )}
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AddDataMSM;
