/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Fetching Data - Web Components
import { useState } from "react";

// Images
import def_image from "assets/images/no-img.jpg";

function ProductsCardMSM({ image, title, description, vendor, majorCategory, category, productUnit, productQtyInUnit, productPrice, action }) {
  const cardActionStyles = {
    display: "flex",
    alignItems: "center",
    width: "max-content",

    "& .material-icons, .material-icons-round,": {
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
      {
        transform: `translateX(6px)`,
      },
  };

  const [isModalOpen, setIsModalOpen] = useState(false); // State lokal untuk mengontrol modal

  // Fungsi untuk membuka modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Fungsi untuk menutup modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  let convert_image = def_image;
  if (image) {
    convert_image = `data:image/jpeg;base64,${image}`;
  }

  const imageTemplate = (
    <MKBox position="relative" borderRadius="lg">
      <MKBox
        component="img"
        src={convert_image}
        alt={title}
        borderRadius="lg"
        shadow="md"
        width="100%"
        position="relative"
        zIndex={1}
        style={{
          aspectRatio: '1 / 1', // Menetapkan aspek rasio 1:1
          objectFit: 'cover', // Mengisi kotak dengan gambar dan memotongnya jika diperlukan
        }}
      />
      <MKBox
        borderRadius="lg"
        shadow="md"
        width="100%"
        height="100%"
        position="absolute"
        left={0}
        top={0}
        sx={{
          backgroundImage: `url(${convert_image})`,
          transform: "scale(0.94)",
          filter: "blur(12px)",
          backgroundSize: "cover",
        }}
      />
    </MKBox>
  );

  return (
    <Card
      sx={{
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      {/* Modal */}
      <Modal open={isModalOpen} onClose={closeModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={isModalOpen} timeout={500}>
          <MKBox
            position="relative"
            width="700px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">{title}</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={closeModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <MKBox
                  component="img"
                  src={convert_image}
                  alt={title}
                  borderRadius="lg"
                  width="100%"
                  position="stick"
                  zIndex={1}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <MKBox p={1} ml={2}>
                  <MKTypography variant="h6" color="dark" fontWeight="regular">
                    <strong>Kategori:</strong> {majorCategory} - {category}
                    <br />
                    <strong>Merk:</strong> {title}
                    <br />
                    <strong>Spesifikasi:</strong> {title} - {description}
                    <br />
                    <strong>Kemasan:</strong> {productQtyInUnit} per {productUnit}
                  </MKTypography>
                </MKBox>
              </Grid>

            </Grid>

            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <div onClick={closeModal} style={{ cursor: "pointer" }} >
                <MKTypography variant="button" fontWeight="regular" color="dark" textTransform="capitalize" ml={1.5}>
                  close
                </MKTypography>
              </div>
              <div>
                {action.type !== "internal" && (
                  <MuiLink href={action.route} target="_blank" rel="noreferrer" mr={2}>
                    <MKTypography variant="button" fontWeight="regular" color="info" textTransform="capitalize">
                      E-katalog
                    </MKTypography>
                  </MuiLink>
                )}
                <MuiLink  href="https://wa.me/6282348661955"  target="_blank" rel="noreferrer" mr={1.5}>
                  <MKTypography variant="button" fontWeight="regular" color="success" textTransform="capitalize">
                    Contact us
                  </MKTypography>
                </MuiLink >
              </div>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>
      {/* End Modal */}

      {/* Tautan atau elemen yang akan membuka modal */}
      {action.type === "internal" ? (
        <div onClick={openModal} style={{ cursor: "pointer" }}>
          {imageTemplate}
        </div>
      ) : (
        <MuiLink target="_blank" rel="noreferrer" onClick={openModal} style={{ cursor: "pointer" }}>
          {imageTemplate}
        </MuiLink>
      )}
      <MKBox pt={1} pb={3}>
        {action.type === "internal" ? (
          <MuiLink onClick={openModal} style={{ cursor: "pointer" }}>
            <MKTypography variant="h5" gutterBottom>
              {title}
            </MKTypography>
          </MuiLink>
        ) : (
          <MuiLink target="_blank" rel="noreferrer" onClick={openModal} style={{ cursor: "pointer" }}>
            <MKTypography variant="h5" gutterBottom>
              {title}
            </MKTypography>
          </MuiLink>
        )}
        <MKTypography variant="body2" component="p" color="text" mb={1}>
          {description}
        </MKTypography>
        {action.type === "internal" ? (
          <MKTypography
            component={Link}
            to={action.route}
            variant="body2"
            fontWeight="regular"
            textTransform="capitalize"
            sx={cardActionStyles}
          >
          </MKTypography>
        ) : (
          <MKTypography
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color={action.color}
            textTransform="capitalize"
            sx={cardActionStyles}
          >
            {action.label}
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        )}
      </MKBox>
    </Card>
  );
}

// Typechecking props for the TransparentBlogCard
ProductsCardMSM.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    label: PropTypes.string,
    color: PropTypes.oneOf([
      "inherit",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "text",
    ]),
  }).isRequired,
};

export default ProductsCardMSM;
