/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from "config/constant";

function CategoryList({ majorCategory, selectedCategories, setSelectedCategories }) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories for the selected major category
    fetch(`${API_SERVER}products/category?majorCategory=${majorCategory}`)
      .then((response) => response.json())
      .then((data) => {
        // Extract unique categories using a Set
        const uniqueCategories = [...new Set(data.map((item) => item.category))];
        setCategories(uniqueCategories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [majorCategory]);

  const handleCheckboxChange = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((item) => item !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
    window.scrollTo(0, 0);
  };

  return (
    <List>
      {categories.map((category, index) => (
        <ListItem
          key={index}
          dense button
          onClick={() => handleCheckboxChange(category)}
          // Add a left margin of 20px (you can adjust the value as needed)
        >
            <Checkbox
              edge="start"
              color="primary"
              checked={selectedCategories.includes(category)}
              tabIndex={-1}
              disableRipple
              style={{ marginLeft: '10px' }}
            />
            <ListItemText
              primary={
                <MKTypography variant="subtitle2">
                  {category}
                </MKTypography>
              }
            />
        </ListItem>
      
      ))}
    </List>
  );
}

export default CategoryList;
