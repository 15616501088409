/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import GoalMSM from "./sections/Goal";
import LocationMSM from "./sections/Location";
import CertificationInfoMSM from "./sections/CertificationInfo";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function AboutUsMSM() {
  const socials = footerRoutes.socials;
  const [companyContentData, setCompanyContentData] = useState([]);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "about_us_title" || item.contentType === "about_us";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://wa.me/6282348661955",
          label: "Contact Now!",
          color: "success",
        }}
        sticky
      />
      {companyContentData
        .filter((contentItem) => contentItem.contentType === "about_us_title")
        .map((contentItem, index) => {
          return (
            <MKBox
              minHeight="75vh"
              width="100%"
              sx={{
                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                  `${linearGradient(
                    rgba(gradients.dark.main, 0.6),
                    rgba(gradients.dark.state, 0.6)
                  )}, url(${`data:image/jpeg;base64,${contentItem.contentImage}`})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Container>
                <Grid
                  container
                  item
                  xs={12}
                  lg={8}
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  sx={{ mx: "auto", textAlign: "center" }}
                >
                  <MKTypography
                    variant="h1"
                    color="white"
                    sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                      },
                    })}
                  >{contentItem.contentTitle}</MKTypography>
                  <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>{contentItem.contentDesc}</MKTypography>
                  <MKButton color="default" href={contentItem.others} target="_blank" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                    See our location
                  </MKButton>
                  <MKTypography variant="h6" color="white" mt={8} mb={1}>
                    Find us on
                  </MKTypography>
                  <MKBox display="flex" justifyContent="center" alignItems="center">
                    {socials.map((social, index) => (
                        <MKBox component="a" key={index}>
                          <MKTypography
                            component={Link}
                            href={social.link}
                            target="_blank"
                            color="white"
                            variant="h5"
                            p={1}
                            
                          >
                            {social.icon}
                          </MKTypography>
                        </MKBox>
                      ))}
                  </MKBox>
                </Grid>
              </Container>
            </MKBox>
          )
      })}
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {companyContentData
        .filter((contentItem) => contentItem.contentType === "about_us")
        .map((contentItem, index) => {
          return (
            <Grid
              container
              item
              xs={12}
              lg={6}
              flexDirection="column"
              alignItems="center"
              sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
            >
              <MKTypography variant="h2" fontWeight="bold" mb={1}>{contentItem.contentTitle}</MKTypography>
              <MKTypography variant="body1" color="text">{contentItem.contentDesc}</MKTypography>
            </Grid>
          )
        })}
        <GoalMSM />
        <LocationMSM />
        <CertificationInfoMSM /> 
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUsMSM;
