/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import React, { useState } from 'react';

const ImageUploader = ({ accept, onChange }) => {
  const [previewImage, setPreviewImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Mengecek ukuran file
      if (file.size > 2 * 1024 * 1024) {
        alert('File terlalu besar. Maksimum 64KB diizinkan.');
        return;
      }

      // Membuat objek Blob dari file gambar
      const blobImage = new Blob([file], { type: file.type });

      // Panggil fungsi onChange dengan objek Blob
      onChange(blobImage);

      // Tampilkan pratinjau gambar
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept={accept}
        onChange={handleImageChange}
      />
      <br />
      <br />
      {previewImage && <img src={previewImage} alt="Preview" style={{ maxWidth: '200px', maxHeight: '250px' }} />}
    </div>
  );
};

export default ImageUploader;
