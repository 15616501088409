/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Link from "@mui/material/Link";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

const NavigationItemMSM = ({ href, label }) => {
  return (
    <MKTypography
      component={Link}
      href={href}
      variant="button"
      color="white"
      fontWeight="regular"
      p={1}
    >
      {label}
    </MKTypography>
  );
};

export default NavigationItemMSM;
