import axios from "./index";
import { API_SERVER } from 'config/constant';

const base = "users";

class AuthApi {
  
  static Login = (data) => axios.post(`${API_SERVER}${base}/login`, data);

  static Register = (data) => axios.post(`${API_SERVER}${base}/register`, data);
  
  static Logout = (data) => axios.post(`${API_SERVER}${base}/logout`, data, { headers: { Authorization: `${data.token}` } });
}

export default AuthApi;
