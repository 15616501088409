/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Welcoming page sections
import NavigationItemMSM from "./sections/NavigationItem";

// Routes
import footerRoutes from 'footer.routes';

// Images
import bgImage from "assets/images/bg-medical.jpg";
import logo from "assets/images/r-nobg-logo-fullside-white.png";

function WelcomingPageMSM() {
  const socials = footerRoutes.socials;
  return (
    <MKBox component="header" position="relative">
      <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
        <Container>
          <Grid container flexDirection="row" alignItems="center">
            <img src={logo} alt="Material Design Logo" style={{ height: "40px", marginRight: "8px", marginTop: "8px" }} />
            <MKButton
              variant="outlined"
              color="white"
              sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}
            >
              <MKBox component="i" color="white" className="fas fa-bars" />
            </MKButton>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <NavigationItemMSM href="/home" label="Home" />
              </MKBox>
              <MKBox component="li">
                <NavigationItemMSM href="/info/about-us" label="About Us" />
              </MKBox>
              <MKBox component="li">
                <NavigationItemMSM href="/contact-us/contacts" label="Contact Us" />
              </MKBox>
            </MKBox>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              {socials.map((social, index) => (
                <MKBox component="li" key={index}>
                  <MKTypography
                    component={Link}
                    href={social.link}
                    target="_blank"
                    color="white"
                    variant="body2"
                    p={1}
                  >
                    {social.icon}
                  </MKTypography>
                </MKBox>
              ))}
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.5), rgba(gradients.dark.state, 0.5))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} md={7} lg={6} flexDirection="column" justifyContent="center">
            <MKTypography
              variant="h3"
              color="white"
            >
              Welcome To
            </MKTypography>
            <MKTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              PT. Multi Sinar Meditron
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} pr={6} mr={6}>
              Enhancing Health Services with <br /> Premium Equipment and Swift Care
            </MKTypography>
            <Stack direction="row" spacing={1} mt={3}>
              <MKButton 
                color="white" 
                href="/products-services/products">
                  See Products!
              </MKButton>
              <MKButton 
                variant="text" 
                color="white"
                href="/info/about-us">
                  Read more
              </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default WelcomingPageMSM;