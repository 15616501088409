/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Images
import location from "assets/images/maps.png"

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function LocationMSM() {  
  const [companyContentData, setCompanyContentData] = useState([]);
  const [companyInfoData, setCompanyInfoData] = useState([]);

  // Function to fetch data
  const fetchData1 = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "location" || item.contentType === "about_us_office" || item.contentType === "about_us_maps";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  // Function to fetch data
  const fetchData2 = () => {
    Axios.get(`${API_SERVER}company/info`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.infoType === "Address";
        });
        setCompanyInfoData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company info data:', error);
      });
  };
    
  useEffect(() => {
    fetchData1();
    fetchData2();
  }, []);

  console.log(companyContentData);

  return (
    <MKBox component="section" py={10}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5} sx={{ mr: "auto", mt: { xs: 3, lg: 0 }}}>
            <MKBox component="section" >
              <Container>
                <Grid container item xs={12} justifyContent="center" mx="auto">
                  {companyContentData
                    .filter((contentItem) => contentItem.contentType === "about_us_office")
                    .map((contentItem, index) => {
                      return(
                      <MKBox mt={{ xs: -9, md: 0 }} textAlign="center">
                        <MKAvatar src={`data:image/jpeg;base64,${contentItem.contentImage}`} size="xxl" shadow="xl" sx={{  ml: 4 }} />
                        <MKBadge
                          variant="contained"
                          color={contentItem.contentDesc}
                          badgeContent={contentItem.contentTitle}
                          container
                          sx={{ mb: 2, mt: 2, ml: 0 }}
                        />
                      </MKBox>
                      )
                  })}
                  
                </Grid>
              </Container>
            </MKBox>
            <MKBox component="section" >
              <Container>
                <Grid container item xs={12} justifyContent="center" mx="auto">
                  {companyContentData
                  .filter((contentItem) => contentItem.contentTitle === "Header")
                  .map((contentItem, index) => (
                    <MKTypography variant="body2" align="center" mt={2} fontSize={16}>
                      {contentItem.contentDesc}
                    </MKTypography>
                  ))}
                  {companyContentData
                  .filter((contentItem) => contentItem.contentTitle === "Content")
                  .map((contentItem, index) => (
                    <MKTypography variant="body1" mt={3} align="center" fontSize={18} color="dark">
                      {contentItem.contentDesc}
                      <br /> 
                      {companyInfoData
                      .filter((infoItem) => infoItem.infoType === "Address")
                      .map((infoItem, index) => (
                        <strong>{infoItem.infoContent}</strong>
                      ))}
                    </MKTypography>
                  ))}
                  {companyContentData
                  .filter((contentItem) => contentItem.contentTitle === "Footer")
                  .map((contentItem, index) => (
                    <MKTypography variant="body2" mt={3} align="center" fontSize={16}>
                      {contentItem.contentDesc}
                    </MKTypography>
                  ))}
                </Grid>
              </Container>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ mt: { xs: 3, lg: 0 }}}>
            {companyContentData
            .filter((contentItem) => contentItem.contentType === "about_us_maps")
            .map((contentItem, index) => {
              return (
              <MKBox
                display="flex"
                alignItems="center"
                borderRadius="xxl"
                my={0}
                py={6}
                mb={5}
                sx={{   
                  backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0),
                      rgba(gradients.dark.state, 0)
                    )}, url(${`data:image/jpeg;base64,${contentItem.contentImage}`})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minHeight: "500px",
                  border: "1px solid #444447",
                  transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                }}
              />
              )
            })}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default LocationMSM;
