/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

function ContactInfoMSM({ icon, label, link, value }) {
    return (
      <MKTypography
        variant="body2"
        component="a"
        href={link}
        target="_blank"
        color="black"
        mb={1}
      >
        {icon && <span sx={{ marginRight: '0.5rem' }}>{icon}</span>}
        <strong> {label}:</strong> {value}<br></br>
      </MKTypography>
    );
  }

export default ContactInfoMSM;