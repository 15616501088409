/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKPagination from "components/MKPagination";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Products page sections
import ProductsMenuMSM from "./sections/ProductsMenu";
import ProductsCardMSM from "./sections/ProductsCard";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Fetching Data - Web Components
import { API_SERVER } from 'config/constant';
import { useEffect, useState } from 'react';

function ProductsMSM() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;
  const totalProducts = filteredData.length;
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  // Filter produk yang ditampilkan berdasarkan halaman saat ini
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = startIndex + productsPerPage;
  const displayedProducts = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0); // Mengarahkan pengguna ke bagian atas halaman
  };  

  useEffect(() => {
    setCurrentPage(1); // Atur ulang currentPage ke 1 ketika terjadi perubahan pada selectedCategories 
  
    // Definisikan URL endpoint API Anda di sini
    const apiUrl = `${API_SERVER}products/all`; // Sesuaikan dengan URL API yang sesuai
  
    // Lakukan fetch data dari API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        } 
        return response.json();
      })
      .then((data) => {
        const filteredData = data.filter((item) => {
          if (selectedCategories.length === 0) {
            // Jika tidak ada kategori yang dipilih, semua item akan ditampilkan
            return true;
          }
          return selectedCategories.includes(item.category);
        });
        setFilteredData(filteredData);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [selectedCategories]);
  
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex="2">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://wa.me/6282348661955",
            label: "Contact Now!",
            color: "success",
          }}
          sticky
        />
      </MKBox>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={3} 
            sx={{ display: { xs: 'none', lg: 'block' } }} 
          >
            <Card
              sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: 15,
                mb: 4,
              }}
            >
              <ProductsMenuMSM
                onSelectedCategoriesChange={setSelectedCategories}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={8} mt={20}>
            <Grid container spacing={3}>
              {displayedProducts.map((item) => (
                <Grid item xs={12} sm={6} lg={3} key={item.idProductMaster}>
                  <ProductsCardMSM
                    image={item.productImage} 
                    title={item.minorCategory || "-"} 
                    description={item.productSpec || "-"}
                    majorCategory={item.majorCategory}
                    category={item.category}
                    productUnit={item.productUnit}
                    productQtyInUnit={item.productQtyInUnit}
                    action={item.productLink ? {
                      type: "external",
                      route: item.productLink,
                      color: "success",
                      label: "e-katalog",
                    } :  {
                      type: "internal",
                      route: "/products/by-categories",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container item justifyContent="center">
              {totalPages > 1 && (
                <MKPagination
                  count={totalPages}
                  page={currentPage}
                  boundaryCount={1}
                  showFirstButton
                  showLastButton
                  onChange={handlePageChange}
                >
                  {currentPage > 1 && (
                    <MKPagination item onClick={() => handlePageChange(currentPage - 1)}>
                      <Icon>keyboard_arrow_left</Icon>
                    </MKPagination>
                  )}
                  {Array.from({ length: totalPages }, (_, index) => {
                    // Hitung selisih antara index halaman saat ini dan halaman terpilih
                    const difference = Math.abs(index + 1 - currentPage);

                    // Tentukan apakah akan menampilkan tautan halaman atau ellipsis
                    if (difference <= 1 || index + 1 === currentPage || index + 1 === 1 || index + 1 === totalPages) {
                      return (
                        <MKPagination
                          item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </MKPagination>
                      );
                    } else if (difference === 2) {
                      // Jika selisihnya 2, tampilkan ellipsis
                      return (
                        <MKPagination
                          item
                          key={`ellipsis-${index}`}
                          disabled
                        >
                          ...
                        </MKPagination>
                      );
                    }
                    return null; // Untuk selisih yang lebih dari 2, abaikan saja (misalnya, 3 atau lebih)
                  })}
                  {currentPage < totalPages && (
                    <MKPagination item onClick={() => handlePageChange(currentPage + 1)}>
                      <Icon>keyboard_arrow_right</Icon>
                    </MKPagination>
                  )}
                </MKPagination>
              )}
            </Grid> 
          </Grid>
        </Grid>
        <MKBox pt={6} px={1} mt={0}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Card> 
      
    </>
  );
}

export default ProductsMSM;
