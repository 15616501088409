/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function HistoryContentMSM() {
  const [companyContentData, setCompanyContentData] = useState([]);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "history_1" || item.contentType === "history_2" || item.contentType === "history_3" || item.contentType === "img_history_1" || item.contentType === "img_history_2" || item.contentType === "img_history_3";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} alignItems="center" mt={1}>
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "img_history_1")
          .map((contentItem, index) => {
            return (
              <Grid item xs={12} lg={3.5} sx={{ mr:"auto", mt: { xs: 3, lg: 0 } }}>
                <CenteredBlogCard
                  image={`data:image/jpeg;base64,${contentItem.contentImage}`}
                  title={contentItem.contentTitle}
                  description={contentItem.contentDesc}
                  action={{
                    type: "internal"
                  }}
                />
              </Grid>
              )
          })}
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "history_1")
          .map((contentItem, index) => (
            <Grid item xs={12} lg={6.5} sx={{ mr:"auto", mt: { xs: 3, lg: 0 }, mb:"auto" }}>
              <MKTypography variant="h3" fontWeight="bold" >{contentItem.contentTitle}</MKTypography><br />
              <MKTypography variant="body1" fontWeight="normal" fontSize={20}>{contentItem.contentDesc}</MKTypography>
            </Grid>
          ))}
          
        </Grid>
        <Grid container spacing={3} alignItems="right" mt={10}>
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "history_2")
          .map((contentItem, index) => (
            <Grid item xs={12} lg={6.5} sx={{ mr:"auto", mt: { xs: 3, lg: 0 }, mb:"auto" }}>
              <MKTypography variant="h3" fontWeight="bold" textAlign="right">{contentItem.contentTitle}</MKTypography><br />
              <MKTypography variant="body1" fontWeight="normal" fontSize={20} textAlign="right">{contentItem.contentDesc}</MKTypography>
            </Grid>
          ))}
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "img_history_2")
          .map((contentItem, index) => {
            return (
              <Grid item xs={12} lg={4} sx={{ mr:"auto", mt: { xs: 3, lg: 0 } }}>
                <CenteredBlogCard
                  image={`data:image/jpeg;base64,${contentItem.contentImage}`}
                  title={contentItem.contentTitle}
                  description={contentItem.contentDesc}
                  action={{
                    type: "internal"
                  }}
                />
              </Grid>
              )
          })}
        </Grid>
        <Grid container spacing={3} alignItems="center" mt={15}>
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "img_history_3")
          .map((contentItem, index) => {
            return (
              <Grid item xs={12} lg={7} sx={{ mr:"auto", mt: { xs: 3, lg: 0 } }}>
                <CenteredBlogCard
                  image={`data:image/jpeg;base64,${contentItem.contentImage}`}
                  title={contentItem.contentTitle}
                  description={contentItem.contentDesc}
                  action={{
                    type: "internal"
                  }}
                />
              </Grid>
              )
          })}
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "history_3")
          .map((contentItem, index) => (
            <Grid item xs={12} lg={5} sx={{ mr:"auto", mt: { xs: 3, lg: 0 }, mb:"auto" }}>
              <MKTypography variant="h3" fontWeight="bold">{contentItem.contentTitle}</MKTypography><br />
              <MKTypography variant="body1" fontWeight="normal" fontSize={20}>{contentItem.contentDesc}</MKTypography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default HistoryContentMSM;
