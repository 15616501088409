/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import def_image from "assets/images/no-img.jpg";

// Fetching Data - Web Components
import { useEffect, useState } from 'react';
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function TeamMSM() {
  const [teamData, setTeamData] = useState([]);
  const [companyContentData, setCompanyContentData] = useState([]);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "team_title";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
    // Lakukan fetching data dari API /api/team/positions di sini
    fetch(`${API_SERVER}team/positions`)
      .then(response => response.json())
      .then(data => {
        setTeamData(data);
      })
      .catch(error => {
        console.error('Error fetching team data:', error);
      });
  }, []);

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      borderRadius="25px"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={5}
      mt={5}
    >
      <Container>
        <Grid container>
          {companyContentData
            .filter((contentItem) => contentItem.contentType === "team_title")
            .map((contentItem, index) => {
              return (
                <Grid item xs={12} md={8} sx={{ mb: 6 }}>
                  <MKTypography variant="h3" color="white">{contentItem.contentTitle}</MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>{contentItem.contentDesc}</MKTypography>
                </Grid>
              )
          })}
        </Grid>
        <Grid container spacing={3}>
          {teamData.map((teamMember, index) => (
            <Grid
              item
              xs={12}
              lg={index === 0 && teamData.length % 2 === 1 ? 7 : 6}
              key={teamMember.idPosition}
              style={{ margin: "auto" }}
            >
              
              <MKBox mb={2}>
                <HorizontalTeamCard
                  image={teamMember.personImage ? `data:image/jpeg;base64,${teamMember.personImage}` : def_image}
                  name={teamMember.personName}
                  position={{ color: "info", label: teamMember.positionName }}
                  description={teamMember.jobDesc}
                />
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TeamMSM;
