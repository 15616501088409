/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React components
import MKBox from "components/MKBox";

function ImageBackground({ imageUrl }) {
  return (
    <MKBox
      minHeight="30rem"
      width="100%"
      borderRadius="20px"
      p={"auto"}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.1),
            rgba(gradients.info.state, 0.1)
          )}, url(${imageUrl})`, 
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
    />
  );
}

export default ImageBackground;
