/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Homa page sections
import InformationMSM from "./sections/Information";
import BestSellerMSM from "./sections/BestSeller";
import CertificationInfoMSM from "company-pages/AbousUsPage/sections/CertificationInfo";
import HomeImageBackground from "./sections/HomeVisualBranding";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import image1 from "assets/images/team/image1-edit.jpg";
import image2 from "assets/images/team/image2.jpg";

// Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function HomePageMSM() {
  const [companyContentData, setCompanyContentData] = useState([]);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "home_title" || item.contentType === "visual_branding";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://wa.me/6282348661955",
          label: "Contact Now!",
          color: "success",
        }}
        sticky
      />
      <Grid item xs={12} lg={12} ml={{ xs: "auto", lg: 0 }} >
        {companyContentData.filter((contentItem) => contentItem.contentType === "visual_branding").length > 0 && (
          <Carousel
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            dynamicHeight={false}
            interval={3500}
            stopOnHover={true}
          >
            {companyContentData
              .filter((contentItem) => contentItem.contentType === "visual_branding")
              .map((contentItem, index) => {
                return (
                  <HomeImageBackground imageUrl={`data:image/jpeg;base64,${contentItem.contentImage}`} />
              )
            })}
          </Carousel>
        )}
      </Grid>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {companyContentData
          .filter((contentItem) => contentItem.contentType === "home_title")
          .map((contentItem, index) => {
            return (
              <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{ textAlign: "center", my: 6, mt: 12, mx: "auto", px: 0.75 }}
              >
                <MKTypography variant="h2" fontWeight="bold">{contentItem.contentTitle}</MKTypography>
                <MKTypography variant="body1" color="text">{contentItem.contentDesc}</MKTypography>
              </Grid>
            )
        })}
        <InformationMSM />
        <BestSellerMSM />
        <CertificationInfoMSM />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default HomePageMSM;
