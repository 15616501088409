/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/office-edit2.png";
import bgBack from "assets/images/pengiriman-paket-edit.jpg";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function InformationMSM() {
  const [companyContentData, setCompanyContentData] = useState([]);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "value" || item.contentType === "home_card_front" || item.contentType === "home_card_back";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              {companyContentData
                .filter((contentItem) => contentItem.contentType === "home_card_front")
                .map((contentItem, index) => (
                  <RotatingCardFront
                    color="dark"
                    image={`data:image/jpeg;base64,${contentItem.contentImage}`}
                    icon={contentItem.others}
                    title={
                      <>
                        {contentItem.contentTitle}
                      </>
                    }
                    description={contentItem.contentDesc}
                  />
              ))}
              {companyContentData
                .filter((contentItem) => contentItem.contentType === "home_card_back")
                .map((contentItem, index) => (
                  <RotatingCardBack
                    color="dark"
                    image={`data:image/jpeg;base64,${contentItem.contentImage}`}
                    title={contentItem.contentTitle}
                    description={contentItem.contentDesc}
                    action={{
                      type: "internal",
                      route: contentItem.others,
                      label: "Lihat seluruh Layanan Kami",
                    }}
                  />
              ))}
              
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <MKTypography variant="h4" mb={2} mt={3}>Kami berkomitmen untuk menanamkan nilai...</MKTypography>
              {companyContentData
                .filter((contentItem) => contentItem.contentType === "value")
                .map((contentItem, index) => {
                  return (
                    <Grid item xs={12} md={6} mb={5}>
                      <DefaultInfoCard
                        icon={contentItem.others}
                        title={contentItem.contentTitle}
                        description={contentItem.contentDesc}
                      />
                    </Grid>
                  )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default InformationMSM;
