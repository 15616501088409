/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Teams page Components
import VacancyItem from "./VacancyItem";

// Routes component
import footerRoutes from 'footer.routes';

// Images
import bgImage from "assets/images/office-edit4.png";

// Fetching Data - Web Components
import { useEffect, useState } from 'react';
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function VacancyMSM() {
  const socials = footerRoutes.socials;
  const [jobVacancies, setJobVacancies] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [companyContentData, setCompanyContentData] = useState([]);
  const [companyInfoData, setCompanyInfoData] = useState([]);

  // Function to fetch data
  const fetchData1 = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "vacancy_title" || item.contentType === "vacancy_info_title" || item.contentType === "about_us_office";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  // Function to fetch data
  const fetchData2 = () => {
    Axios.get(`${API_SERVER}company/info`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.infoType === "WhatsApp" || item.infoType === "Email" || item.infoType === "Address";
        });
        setCompanyInfoData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company info data:', error);
      });
  };

  useEffect(() => {
    fetchData1();
    fetchData2();
    // Fetch job vacancies from the API when the component mounts
    Axios.get(`${API_SERVER}careers/vacancy`)
      .then((response) => {
        const filteredData = response.data.filter((vacancyItem) => vacancyItem.status === true);
        setJobVacancies(filteredData); // Update the state with fetched data
      })
      .catch((error) => {
        console.error('Error fetching job vacancies:', error);
      });
  }, []); // Empty dependency array ensures this effect runs once on mount

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            mt={5}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${`data:image/jpeg;base64,${companyContentData.find((contentItem) => contentItem.contentType === "about_us_office")?.contentImage}`})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    {companyContentData
                    .filter((contentItem) => contentItem.contentType === "vacancy_info_title")
                    .map((contentItem, index) => {
                      return(
                        <div>
                          <MKTypography variant="h3" color="white" mb={1}>{contentItem.contentTitle}</MKTypography>
                          <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>{contentItem.contentDesc}</MKTypography>
                        </div>
                      )
                    })}
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {companyInfoData.find((infoItem) => infoItem.infoType === "WhatsApp")?.infoContent}
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {companyInfoData.find((infoItem) => infoItem.infoType === "Email")?.infoContent}
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {companyInfoData.find((infoItem) => infoItem.infoType === "Address")?.infoContent}
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                      {socials.map((social, index) => (
                        <MKButton
                          key={index}
                          variant="text"
                          color="white"
                          size="large"
                          iconOnly
                          component="a" // Membuat tombol sebagai tautan
                          href={social.link}
                          target="_blank" // Buka tautan dalam tab baru
                        >
                          {social.icon}
                        </MKButton>
                      ))}
                    </MKBox>

                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox>
                  {companyContentData
                  .filter((contentItem) => contentItem.contentType === "vacancy_title")
                  .map((contentItem, index) => {
                    return (
                      <MKBox px={2} py={{ xs: 1, sm: 3 }}>
                        <MKTypography variant="h2" mb={1}>{contentItem.contentTitle}</MKTypography>
                        <MKTypography variant="body1" color="text">{contentItem.contentDesc}</MKTypography>
                      </MKBox>
                    )
                  })}
                  <MKBox pb={5} mr={2} px={2}>
                    {jobVacancies.map((job, index) => (
                      <VacancyItem
                        key={index}
                        title={job.jobTitle}
                        desc={job.jobDesc}
                        req={job.jobReq }
                        expanded={expanded === `panel${index + 1}`}
                        onChange={handleAccordionChange(`panel${index + 1}`)}
                      />
                    ))}
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default VacancyMSM;
