/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Card, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// Material Kit 2 React components
import MKAlert from 'components/MKAlert';
import MKBox from "components/MKBox";
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import adminRoutes from 'adminRoutes';
import footerRoutes from "footer.routes";

// Fetching Data - Web Components
import { useAuth } from "auth-context/auth.context";
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from "axios";

// CS Tickets page components
import ModalMSM from 'company-admin-pages/ModalComponents/modal';

const columns = [
  {
    field: "idTicket",
    type: "number",
    headerName: "ID",
    width: 90,
  },
  {
    field: "create_date",
    headerName: "Tanggal Kirim",
    width: 180,
  },
  {
    field: "name",
    headerName: "Nama",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "phoneNum",
    headerName: "No. Hp",
    width: 170,
  },
  {
    field: "title",
    headerName: "Judul",
    width: 250,
  },
  {
    field: "message",
    headerName: "Pesan",
    width: 320,
  },
];

function CSTicketsAdmin() {
  const { user } = useAuth();
  const [ticketsData, setCSTicketsData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [selectedData, setSelectedData] = useState(null); 
  const [isCheckboxSelectionEnabled, setIsCheckboxSelectionEnabled] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}cstickets`)
      .then((response) => {
        const allData = response.data;
        // Menambahkan properti id yang unik
        allData.forEach((item, index) => {
          item.id = `${item.idTicket}`;
          if (item.company === null) {
            item.company = "-";
          }
        });
        // Sort data berdasarkan create_date secara menurun (dari yang terbaru)
        allData.sort((a, b) => {
          return new Date(b.create_date) - new Date(a.create_date);
        });
        setCSTicketsData(allData);
      })
      .catch((error) => {
        console.error('Error fetching company CS Tickets data:', error);
      }
    );
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  // Fungsi untuk membuka modal dan menetapkan data yang akan ditampilkan dalam modal
  const openModal = (data) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  // Fungsi untuk menutup modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedData(null); // Setel data yang ditampilkan dalam modal menjadi null
  };

  // Function untuk mengubah status switch
  const handleCheckboxSelectionToggle = () => {
    setIsCheckboxSelectionEnabled(!isCheckboxSelectionEnabled);
  };

  // Fungsi untuk menampilkan modal konfirmasi sebelum menghapus
  const handleDeleteRow = (row) => {
    setSelectedRowToDelete(row);
    setIsDeleteModalOpen(true);
  };

  // Fungsi untuk menghapus baris setelah konfirmasi
  const confirmDeleteRow = () => {
    if (selectedRowToDelete) {
      Axios.delete(`${API_SERVER}cstickets/${selectedRowToDelete.idTicket}`)
        .then((response) => {
          // Setelah menghapus, perbarui daftar tiket dengan mengambil data baru
          fetchData();
          setSubmissionStatus('success');
          setTimeout(() => {
            setSubmissionStatus(null); // Reset submissionStatus
          }, 3000);
        })
        .catch((error) => {
          setSubmissionStatus('error');
          setTimeout(() => {
            setSubmissionStatus(null); // Reset submissionStatus
          }, 3000);
        })
        .finally(() => {
          // Setelah penghapusan, tutup modal konfirmasi
          setIsDeleteModalOpen(false);
          setSelectedRowToDelete(null);
        });
    }
  };

  return (
    <>
      {user && user.token ? (
        <DefaultNavbar
          routes={adminRoutes}
          action={{
            type: "internal",
            route: "/admin-multi-sinar/sign-out",
            label: "logout",
            color: "error",
          }}
          sticky
        />
      ) : (
        <DefaultNavbar
        routes={adminRoutes}
          action={{
            type: "external",
            route: "/",
            label: "Website",
            color: "info",
          }}
          sticky
        />
      )}
      <Card
        sx={{
          p: 2,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          position="fixed"
          top="5.5rem"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="9999"
        >
          {submissionStatus === 'success' && (
            <MKAlert color="success" dismissible>Ticket Deleted!</MKAlert>
          )}
          {submissionStatus === 'error' && (
            <MKAlert color="error" dismissible>There's an error occur!</MKAlert>
          )}
        </MKBox>
        <Card sx={{ width: '100%', mb: 2, mt: 17 }}>
          <MKTypography variant="h4" sx={{ textAlign: 'center' }}>
            Customer Service Tickets
          </MKTypography>
          <FormControlLabel
            control={<Switch
              checked={isCheckboxSelectionEnabled}
              onChange={handleCheckboxSelectionToggle}
              />}
            label="Select Data to Delete"
            sx={{ textAlign: 'right' }}
          />
          {ticketsData.length === 0 ? (
            <MKTypography variant="h5" sx={{ mt: 10, mb: 10, textAlign: 'center', color: 'red', fontStyle: 'italic' }}>
              Data Belum Tersedia untuk Saat Ini
            </MKTypography>
          ) : (
            isCheckboxSelectionEnabled ? (
              <DataGrid
                rows={ticketsData}
                columns={[
                  {
                    field: "delete",
                    headerName: "",
                    width: 55,
                    renderCell: (params) => (
                      <MKButton
                        variant="text"
                        size="small"
                        color="error"
                        onClick={() => handleDeleteRow(params.row)}
                      >
                        Del
                      </MKButton>
                    ),
                  },
                  ...columns,
                ]}
                getRowId={(row) => row.idTicket}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
              />
            ) : (
              <DataGrid
                rows={ticketsData}
                columns={columns}
                getRowId={(row) => row.idTicket}
                onRowClick={(params) => openModal(params.row)} // Tambahkan ini untuk memungkinkan klik baris saat seleksi tidak aktif
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
                // Tidak menggunakan checkboxSelection jika isCheckboxSelectionEnabled=false
              />
            )
          )}
        </Card>
      </Card>
      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        aria-labelledby="delete-ticket-dialog"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="delete-ticket-dialog">Konfirmasi Penghapusan</DialogTitle>
        <DialogContent>
          Apakah Anda yakin ingin menghapus tiket ini?
        </DialogContent>
        <DialogActions>
          <MKButton onClick={() => setIsDeleteModalOpen(false)} color="success">
            Batal
          </MKButton>
          <MKButton onClick={confirmDeleteRow} color="error">
            Hapus
          </MKButton>
        </DialogActions>
      </Dialog>

      {/* Render modal */}
      {selectedData && (
        <ModalMSM isOpen={isModalOpen} closeModal={closeModal} title={"Tiket"} data={selectedData} columns={columns} isUpdate={false} isImage={false}/>
      )}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default CSTicketsAdmin;
