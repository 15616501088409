/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function BestSellerMSM() {
  const [companyContentData, setCompanyContentData] = useState([]);

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "best_seller" || item.contentType === "best_seller_nav";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MKBox component="section" py={2} mb={10}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Best Seller Products
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "best_seller")
          .map((contentItem, index) => (
            <Grid item xs={12} sm={6} lg={3}>
              <TransparentBlogCard
                image={`data:image/jpeg;base64,${contentItem.contentImage}`}
                title={contentItem.contentTitle}
                description={contentItem.contentDesc}
                action={{
                  type: "external",
                  route: contentItem.others,
                }}
              />
            </Grid>
            )
          )}
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "best_seller_nav")
          .map((contentItem, index) => (
            <Grid item xs={12} sm={6} lg={3}>
              <BackgroundBlogCard
                image={`data:image/jpeg;base64,${contentItem.contentImage}`}
                title={contentItem.contentTitle}
                description={contentItem.contentDesc}
                action={{
                  type: "internal",
                  route: contentItem.others,
                  label: "Search now",
                  color: "secondary"
                }}
              />
            </Grid>
            )
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BestSellerMSM;
