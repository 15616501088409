/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Images
import bgImage from "assets/images/bg-blue-abs6.jpg";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function CertificationInfoMSM() {
  const [companyContentData, setCompanyContentData] = useState([]);
  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "certifications" || item.contentType === "product_main_navs";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container>
        <Grid container spacing={3} sx={{ mb: 4 }} justifyContent="center">
          <MKTypography variant="h3" color="dark">
            Sertifikasi membuktikan Standar Keunggulan Kami
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 12 }} justifyContent="center">
          {companyContentData
          .filter((contentItem) => contentItem.contentType === "certifications")
            .map((contentItem, index) => {
              return (
                <Grid item xs={6} md={4} lg={2}>
                  <MKBox component="img" src={`data:image/jpeg;base64,${contentItem.contentImage}`} alt="coinbase" width="100%" opacity={0.9} />
                </Grid>
              )
          })}
        </Grid>
      </Container>
      <MKBox
        variant="gradient"
        bgColor="secondary"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden" }}
      >
        {companyContentData
          .filter((contentItem) => contentItem.contentType === "product_main_navs")
          .map((contentItem, index) => {
            return (
              <div>
                <MKBox
                  component="img"
                  src={`data:image/jpeg;base64,${contentItem.contentImage}`}
                  alt="pattern-lines"
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  zIndex={1}
                  opacity={0.4}
                />
                <Container sx={{ position: "relative", zIndex: 2, py: 12 }}>
                  <Grid container item xs={12} md={7} justifyContent="center" mx="auto" textAlign="center">
                    <MKTypography variant="h3" color="white" mb={1}>{contentItem.contentTitle}</MKTypography>
                    <MKTypography variant="body2" color="white" mb={6}>{contentItem.contentDesc}</MKTypography>
                    <MKButton
                      variant="gradient"
                      color="info"
                      size="medium"
                      component="a"
                      href={contentItem.others}
                      target="_blank"
                      sx={{ mb: 2 }}
                    >
                      See Our Products
                    </MKButton>
                  </Grid>
                </Container>
              </div>
            )
        })}
        
      </MKBox>
    </MKBox>
  );
}

export default CertificationInfoMSM;
