/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAlert from 'components/MKAlert';
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Contact Us page sections
import ContactInfoMSM from "./sections/ContactInfo";
import ContactFormMSM from "./sections/ContactForm";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import maps from "assets/images/maps.png"

// @mui material icons
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import BusinessIcon from "@mui/icons-material/Business";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios'



function ContactUsMSM() {
  const [activeTab, setActiveTab] = useState(0);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const [companyContentData, setCompanyContentData] = useState([]);
  const iconComponents = {
    WhatsAppIcon,
    EmailIcon,
    PhoneIcon,
    FaxIcon,
    BusinessIcon,
  };
  const handleTabType = (event, newValue) => setActiveTab(newValue);

  // Callback function to update submissionStatus
  const updateSubmissionStatus = (status) => {
    setSubmissionStatus(status);
  };

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/info`)
      .then((response) => {
        const allData = response.data;
        setCompanyInfoData(allData);
      })
      .catch((error) => {
        console.error('Error fetching company info data:', error);
      });
  };

  // Function to fetch data
  const fetchData2 = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "contact_maps";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchData2();
  }, []);


  useEffect(() => {
    fetchData();
  }, []); 

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex="1">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://wa.me/6282348661955",
            label: "Contact Now!",
            color: "success",
          }}
          sticky
        />
      </MKBox>
      <MKBox
        position="fixed"
        top="5.5rem"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex="9999"
      >
        {submissionStatus === 'success' && (
          <MKAlert color="success" dismissible>Your message sent!</MKAlert>
        )}
        {submissionStatus === 'error' && (
          <MKAlert color="error" dismissible>There's an error occur!</MKAlert>
        )}
      </MKBox>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          {companyContentData
            .filter((contentItem) => contentItem.contentType === "contact_maps")
            .map((contentItem, index) => {
              return (
                <MKBox
                  display={{ xs: "none", lg: "flex" }}
                  width="calc(100% - 2rem)"
                  height="calc(100vh - 2rem)"
                  borderRadius="lg"
                  ml={2}
                  mt={2}
                  sx={{ backgroundImage: `url(${`data:image/jpeg;base64,${contentItem.contentImage}`})` }}
                />
              )
          })}
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 10 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', 
              }}
            >
              <MKTypography variant="h3" color="white" >
                PT. Multi Sinar Meditron
              </MKTypography>
            </MKBox>
            <MKBox mt={1}>
              <Grid container item justifyContent="center" xs={12} lg={6} mx="auto">
                <AppBar position="static"  style={{ zIndex: 0 }}>
                  <Tabs value={activeTab} onChange={handleTabType}>
                    <Tab label="Form" />
                    <Tab label="Info" />
                  </Tabs>
                </AppBar>
              </Grid>
            </MKBox>
              {activeTab === 0 ? (
                <MKBox p={2}>
                  <MKTypography variant="body2" color="black" mb={2}>
                    For further questions, including partnership opportunities, please email{" "}
                    <strong>
                      {companyInfoData.find((infoItem) => infoItem.infoType === "Email")?.infoContent}
                    </strong> or contact using our contact form.
                  </MKTypography>
                  <ContactFormMSM updateSubmissionStatus={updateSubmissionStatus} />
                </MKBox>
              ) : (
                <MKBox p={2}>
                  {companyInfoData.map((infoItem, index) => {
                    const IconComponent = iconComponents[infoItem.infoIcon]; 
                    return (
                      <ContactInfoMSM
                        key={index}
                        icon={<IconComponent />} 
                        label={infoItem.infoType}
                        link={infoItem.infoLink}
                        value={infoItem.infoContent}
                      />
                    );
                  })}
                </MKBox>
              )}
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUsMSM;