/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

// Products page sections
import MajorCategoryList from "./MajorCategoryList";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';


function ProductsMenuMSM({ onSelectedCategoriesChange }) {
  // Category Const and Handler
  const [majorCategories, setMajorCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]); 

  const handleSelectedCategoriesChange = (categories) => {
    setSelectedCategories(categories); // Callback untuk menerima selectedCategories dari MajorCategoryList
  };


  useEffect(() => {
    // Define the URLs for both API endpoints
    const categoryUrl = `${API_SERVER}products/category`;
  
    // Fetch category data
    fetch(categoryUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error fetching category data');
        }
        return response.json();
      })
      .then((categoryData) => {
        // Extract unique major categories using a Set
        const uniqueMajorCategories = [...new Set(categoryData.map((item) => item.majorCategory))];
        setMajorCategories(uniqueMajorCategories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });      
  }, []);

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item>
          <MKTypography variant="h4" verticalAlign="middle" mb={1} >
            See our Products
          </MKTypography>
        </Grid>
        <Grid mb={3}>
          <div spacing={0.5}>
            {selectedCategories.map((category, index) => (
              <MKBadge
                key={index}
                badgeContent={category}
                variant="contained"
                color="info"
                container
                border
                circular
                sx={{ zIndex: 1 }}
              />
            ))}
            {onSelectedCategoriesChange(selectedCategories)}
          </div>
          <Divider variant="middle" sx={{ mt: 2, mb: 1, borderBottom: "2px solid #ccc" }} />
        </Grid>
        <Grid container spacing={3}>
          <Grid item >
            <MKTypography variant="h5" mb={1} ml={0.5}>Category</MKTypography>
            <MajorCategoryList
              majorCategories={majorCategories}
              onSelectedCategoriesChange={handleSelectedCategoriesChange}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ProductsMenuMSM;
