/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { styled } from '@mui/system';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Kit 2 React components
import MKTypography from 'components/MKTypography';

// Define custom pastel color values
const pastelColor1 = '#e6effa';
const pastelColor2 = '#f0f0fc';

// All of em
const StyledAccordion = styled(Accordion)(({ theme  }) => ({
  background: pastelColor1,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(0),
  '&:hover': {
    boxShadow: `0px 4px 8px ${theme.palette.action.hoverShadow}`,
  },
}));

// The Question
const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.contrastText,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(1),
  },
}));

// The Answer
const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: pastelColor2,
}));

function FaqItemMSM({ question, answer, expanded, onChange }) {
  // Memisahkan bagian "answer" menjadi poin-poin
  const answerPoints = answer.split('\n');

  return (
    <StyledAccordion expanded={expanded} onChange={onChange}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${question}-content`}
        id={`${question}-header`}
      >
        <MKTypography variant={expanded ? 'h5' : 'h6'} color="dark">{question}</MKTypography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <MKTypography variant="body2">
          {answerPoints.map((point, index) => (
            <MKTypography variant="body2" key={index}>{point}</MKTypography>
          ))}
        </MKTypography>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}


export default FaqItemMSM;
