/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Card, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from "@mui/material/Grid";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Material Kit 2 React components
import MKAlert from 'components/MKAlert';
import MKBox from "components/MKBox";
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import adminRoutes from 'adminRoutes';
import footerRoutes from "footer.routes";

// Fetching Data - Web Components
import { useAuth } from "auth-context/auth.context";
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from "axios";

// CS Tickets page components
import ModalMSM from 'company-admin-pages/ModalComponents/modal';
import UpdateModalMSM from 'company-admin-pages/ModalComponents/updateModal';

function DataViewMSM() {
  const { user } = useAuth();
  const [tableData, setTableData] = useState({data: [], columns: [], });
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [selectedData, setSelectedData] = useState(null); 
  const [isCheckboxDeleteSelectionEnabled, setisCheckboxDeleteSelectionEnabled] = useState(false);
  const [isCheckboxUpdateSelectionEnabled, setisCheckboxUpdateSelectionEnabled] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);

  // Function to fetch data
  const fetchDataFromTable = (tableName) => {
    // Tentukan endpoint API berdasarkan jenis tabel yang dipilih
    let endpoint = '';
    if (tableName === "job_vacancy") {
      endpoint = "careers/vacancy"
    } else if (tableName === "faq") {
        endpoint = "faq";
    } else if (tableName === "team_position") {
      endpoint = "team/positions"
    } else if (tableName === "products") {
      endpoint = "products/all"
    } else if (tableName === "product_category") {
      endpoint = "products/category"
    } else if (tableName === "product_details") {
      endpoint = "products/details"
    } else if (tableName === "product_master") {
      endpoint = "products/master"
    } else if (tableName === "product_vendor") {
      endpoint = "products/vendor"
    } else if (tableName === "company_content") {
      endpoint = "company/content";
    } else if (tableName === "company_info") {
      endpoint = "company/info";
    }

    const api_url = `${API_SERVER}${endpoint}`;
    // Fetch data dari API
    fetch(api_url)
    .then(response => response.json())
    .then(data => {
      // Ganti nilai null dengan "-"
      const newData = data.map((item, index) => {
        for (const key in item) {
          if (item[key] === null) {
            item[key] = "-";
          } else if (item[key] === true) {
            item[key] = "Tampilkan";
          } else if (item[key] === false) {
            item[key] = "Sembunyikan";
          }
        }
        return item;
      });  

      // Membuat kolom (columns) berdasarkan nama field dalam data
      const columns = Object.keys(newData[0]).map((field, index) => {
        const column = {
          field: field,
          headerName: field.charAt(0).toUpperCase() + field.slice(1), // Membuat judul dengan huruf kapital pertama
          width: 200, // Atur lebar sesuai kebutuhan Anda
          align: index === 0 ? "right" : "left",
          headerAlign: index === 0 ? "right" : "left",
        };
      
        return column;
      });
      

      newData.forEach((item, index) => {
        const temp_column = Object.keys(item)[0];
        item.id = item[temp_column].toString(); // Pastikan id dalam bentuk string
      });
      

      // Sort berdasarkan kolom pertama (asumsikan kolom pertama adalah "id")
      newData.sort((a, b) => a.id - b.id);
  

      // Lakukan sesuatu dengan data yang sudah di-fetch, diganti, dan columns yang sudah dibuat
      setTableData({ data: newData, columns: columns });
    })
    .catch(error => {
      console.error("Terjadi kesalahan dalam fetch data:", error);
    });
  };

  // Fungsi untuk membuka modal dan menetapkan data yang akan ditampilkan dalam modal
  const openModal = (data) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  // Fungsi untuk menutup modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedData(null); // Setel data yang ditampilkan dalam modal menjadi null
  };

  // Fungsi untuk menutup modal
  const closeUpdateModal = () => {
    fetchDataFromTable(selectedTable);
    setIsModalOpen(false);
    setSelectedData(null); // Setel data yang ditampilkan dalam modal menjadi null
  };

  // Function untuk mengubah status switch update
  const handleCheckboxUpdateSelectionToggle = () => {
    setisCheckboxUpdateSelectionEnabled(!isCheckboxUpdateSelectionEnabled);
    setisCheckboxDeleteSelectionEnabled(false);
  };

  // Function untuk mengubah status switch delete
  const handleCheckboxDeleteSelectionToggle = () => {
    setisCheckboxDeleteSelectionEnabled(!isCheckboxDeleteSelectionEnabled);
    setisCheckboxUpdateSelectionEnabled(false);
  };

  // Fungsi untuk menjalankan modal update
  const handleUpdateRow = (row) => {
    setSelectedData(row); // Simpan data yang akan diupdate
    setIsUpdateModalOpen(true); // Buka modal update
  };
  
  // Fungsi untuk menampilkan modal konfirmasi sebelum menghapus
  const handleDeleteRow = (row) => {
    setSelectedData(row);
    setIsDeleteModalOpen(true);
  };

  // Fungsi untuk menangani perubahan saat tabel dipilih
  const handleTableSelectChange = (e) => {
    const selectedTableName = e.target.value;
    setSelectedTable(selectedTableName);
    setisCheckboxDeleteSelectionEnabled(false);
    setisCheckboxUpdateSelectionEnabled(false); 
    // Panggil fungsi fetchDataFromTable dengan nama tabel yang dipilih
    fetchDataFromTable(selectedTableName);
  };

  // Fungsi untuk menghapus baris setelah konfirmasi
  const confirmDeleteRow = () => {
    if (selectedData) {
      let endpoint = '';
      const tableName = selectedTable;
      if (tableName === "job_vacancy") {
        endpoint = "careers/vacancy"
      } else if (tableName === "faq") {
          endpoint = "faq";
      } else if (tableName === "team_position") {
        endpoint = "team/positions"
      } else if (tableName === "products") {
        endpoint = "products/all"
      } else if (tableName === "product_category") {
        endpoint = "products/category"
      } else if (tableName === "product_details") {
        endpoint = "products/details"
      } else if (tableName === "product_master") {
        endpoint = "products/master"
      } else if (tableName === "product_vendor") {
        endpoint = "products/vendor"
      } else if (tableName === "company_content") {
        endpoint = "company/content";
      } else if (tableName === "company_info") {
        endpoint = "company/info";
      }
      Axios.delete(`${API_SERVER}${endpoint}/${selectedData.id}`)
        .then((response) => {
          // Setelah menghapus, perbarui daftar tiket dengan mengambil data baru
          fetchDataFromTable(tableName);
          setSubmissionStatus('success');
          setTimeout(() => {
            setSubmissionStatus(null); // Reset submissionStatus
          }, 3000);
        })
        .catch((error) => {
          // Handle error dari API jika perlu
          console.error('Error deleting:', error);
          setSubmissionStatus('error');
          setTimeout(() => {
            setSubmissionStatus(null); // Reset submissionStatus
          }, 3000);
        })
        .finally(() => {
          // Setelah penghapusan, tutup modal konfirmasi
          setIsDeleteModalOpen(false);
          setSelectedData(null);
        });
    }
  };

  // Filter kolom yang tidak mengandung kata "image"
  const filteredColumns = tableData.columns.filter((column) => !column.field.toLowerCase().includes("image"));

  return (
    <>
      {user && user.token ? (
        <DefaultNavbar
          routes={adminRoutes}
          action={{
            type: "internal",
            route: "/admin-multi-sinar/sign-out",
            label: "logout",
            color: "error",
          }}
          sticky
        />
      ) : (
        <DefaultNavbar
        routes={adminRoutes}
          action={{
            type: "external",
            route: "/",
            label: "Website",
            color: "info",
          }}
          sticky
        />
      )}
      <Card
        sx={{
          p: 2,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          position="fixed"
          top="5.5rem"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="9999"
        >
          {submissionStatus === 'success' && (
            <MKAlert color="success" dismissible>Process Success!</MKAlert>
          )}
          {submissionStatus === 'error' && (
            <MKAlert color="error" dismissible>There's an error occur!</MKAlert>
          )}
        </MKBox>
        <Card sx={{ width: '100%', mb: 2, mt: 17 }}>
          <MKTypography variant="h4" sx={{ textAlign: 'center' }}>
            Data View
          </MKTypography>
          <Grid item xs={12} md={12} sx={{ p:3, textAlign: 'center' }}>
            <Select
              variant="standard"
              fullWidth
              value={selectedTable}
              onChange={handleTableSelectChange}
              displayEmpty 
              
            >
              <MenuItem value="" disabled><MKTypography variant="h6">Select Table</MKTypography></MenuItem>
              <MenuItem value="products"><MKTypography variant="body2">All Products Data</MKTypography></MenuItem>
              <MenuItem value="product_category"><MKTypography variant="body2">Product Category</MKTypography></MenuItem>
              <MenuItem value="product_details"><MKTypography variant="body2">Product Details</MKTypography></MenuItem>
              <MenuItem value="product_master"><MKTypography variant="body2">Product Master</MKTypography></MenuItem>
              <MenuItem value="product_vendor"><MKTypography variant="body2">Product Vendor</MKTypography></MenuItem>
              <MenuItem value="company_content"><MKTypography variant="body2">Company Content</MKTypography></MenuItem>
              <MenuItem value="company_info"><MKTypography variant="body2">Company Info</MKTypography></MenuItem>
              <MenuItem value="faq"><MKTypography variant="body2">FAQ Page</MKTypography></MenuItem>
              <MenuItem value="job_vacancy"><MKTypography variant="body2">Job Vacancy</MKTypography></MenuItem>
              <MenuItem value="team_position"><MKTypography variant="body2">Team Position</MKTypography></MenuItem>
            </Select>
          </Grid>
            <Grid container justifyContent="right">
              {selectedTable && selectedTable !== "company_content" && selectedTable !== "company_info" && selectedTable !== "products" && (
                <FormControlLabel
                  control={<Switch
                    checked={isCheckboxDeleteSelectionEnabled}
                    onChange={handleCheckboxDeleteSelectionToggle}
                  />}
                  label={<span style={{ color: 'red' }}>Select Data to Delete</span>}
                  sx={{ textAlign: 'right' }}
                />
              )}
              {selectedTable && selectedTable !== "products" && (
                <FormControlLabel
                  control={<Switch
                    checked={isCheckboxUpdateSelectionEnabled}
                    onChange={handleCheckboxUpdateSelectionToggle}
                  />}
                  label={<span style={{ color: 'green' }}>Select Data to Update</span>}
                  sx={{ textAlign: 'right' }}
                />
              )}
            </Grid>

          {tableData.data.length === 0 ? (
            <MKTypography variant="h5" sx={{ mt: 10, mb: 10, textAlign: 'center', color: 'red', fontStyle: 'italic' }}>
              Data Belum Tersedia untuk Saat Ini
            </MKTypography>
          ) : (
            isCheckboxDeleteSelectionEnabled ? (
              <DataGrid
                rows={tableData.data}
                columns={[
                  {
                    field: "delete",
                    headerName: "",
                    width: 100,
                    renderCell: (params) => (
                      <MKButton
                        variant="text"
                        size="small"
                        color="error"
                        onClick={() => handleDeleteRow(params.row)}
                      >
                        Delete
                      </MKButton>
                    ),
                  },
                  ...filteredColumns,
                ]}
                getRowId={(row) => row.id}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
              />
            ) : isCheckboxUpdateSelectionEnabled ? (
              <DataGrid
                rows={tableData.data}
                columns={[
                  {
                    field: "update",
                    headerName: "",
                    width: 100,
                    renderCell: (params) => (
                      <MKButton
                        variant="text"
                        size="small"
                        color="success"
                        onClick={() => handleUpdateRow(params.row)}
                      >
                        Update
                      </MKButton>
                    ),
                  },
                  ...filteredColumns,
                ]}
                getRowId={(row) => row.id}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
              />
            ) : (
              <DataGrid
                rows={tableData.data}
                columns={filteredColumns}
                getRowId={(row) => row.id}
                onRowClick={(params) => openModal(params.row)}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
              />
            )
          )}
        </Card>
      </Card>
      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        aria-labelledby="delete-ticket-dialog"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="delete-ticket-dialog">Konfirmasi Penghapusan</DialogTitle>
        <DialogContent>
          Apakah Anda yakin ingin menghapus baris data ini?
        </DialogContent>
        <DialogActions>
          <MKButton onClick={() => setIsDeleteModalOpen(false)} color="success">
            Batal
          </MKButton>
          <MKButton onClick={confirmDeleteRow} color="error">
            Hapus
          </MKButton>
        </DialogActions>
      </Dialog>
      {selectedData && ( 
        (selectedTable === "team_position" || selectedTable === "product_category" || selectedTable === "products" || selectedTable === "company_content") ? (
          <ModalMSM isOpen={isModalOpen} closeModal={closeModal} title={selectedTable} data={selectedData} columns={tableData.columns} isImage={true} image={selectedData[Object.keys(selectedData).find(key => key.toLowerCase().includes("image"))]}/>
        ) : (
          <ModalMSM isOpen={isModalOpen} closeModal={closeModal} title={selectedTable} data={selectedData} columns={tableData.columns} isImage={false}/>
        )
      )}
      {selectedData && isCheckboxUpdateSelectionEnabled && ( 
        (selectedTable === "team_position" || selectedTable === "product_category" || selectedTable === "products" || selectedTable === "company_content") ? (
          <UpdateModalMSM 
            isOpen={isUpdateModalOpen} 
            closeModal={closeUpdateModal}
            title={`Update ${selectedTable}`}
            data={selectedData}
            columns={tableData.columns}
            isImage={true} 
            image={selectedData[Object.keys(selectedData).find(key => key.toLowerCase().includes("image"))]} // Sesuaikan dengan kondisi Anda
            table={selectedTable}
            setSubmissionStatus={setSubmissionStatus} // Kirim fungsi setSubmissionStatus ke modal
          />
        ) : (
          <UpdateModalMSM 
            isOpen={isUpdateModalOpen} 
            closeModal={closeUpdateModal}
            title={`Update ${selectedTable}`}
            data={selectedData}
            columns={tableData.columns}
            isImage={false}
            table={selectedTable}
            setSubmissionStatus={setSubmissionStatus} // Kirim fungsi setSubmissionStatus ke modal
          />
        )
      )}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default DataViewMSM;
