/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import MuiLink from '@mui/material/Link';
// Images
import def_image from "assets/images/no-img.jpg";

function ModalMSM({ isOpen, closeModal, title, data, columns, isImage, image }) {
  let convert_image = def_image;
  if (image && image !== "-") {
    convert_image = `data:image/jpeg;base64,${image}`;
  } 
  return (
    <Modal open={isOpen} onClose={closeModal} sx={{ display: 'grid', placeItems: 'center' }}>
      <Slide direction="down" in={isOpen} timeout={500}>
        <MKBox
          position="relative"
          width="800px"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          bgColor="white"
          shadow="xl"
        >
          <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
            <MKTypography variant="h5">{title} dengan ID {`${data.id}`}</MKTypography>
            <CloseIcon fontSize="medium" sx={{ cursor: 'pointer' }} onClick={closeModal} />
          </MKBox>
          <Divider sx={{ my: 0 }} />
          {isImage ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <MKBox
                  component="img"
                  src={convert_image}
                  borderRadius="lg"
                  width="100%"
                  position="stick"
                  zIndex={1}
                  sx={{ ml: 3 }}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <MKBox p={1} ml={2}>
                  {columns.map((column) => (
                    // Cek apakah nama kolom mengandung "image" (tanpa memperhatikan casing)
                    !column.field.toLowerCase().includes("image") && (
                      <MKTypography variant="body2" key={column.field} sx={{ ml: 3, mr: 5 }}>
                        <strong>{column.headerName}:</strong> {data[column.field]}
                      </MKTypography>
                    )
                  ))}
                </MKBox>
              </Grid>
            </Grid>
          ) : (
            columns.map((column) => (
              <MKTypography variant="body2" key={column.field} sx={{ ml: 4, mr: 5 }}>
                <strong>{column.headerName}:</strong> {data[column.field]}
              </MKTypography>
            ))
          )}
          <Divider sx={{ my: 0 }} />
          <MKBox display="flex" justifyContent="space-between" p={1.5}>
            <div onClick={closeModal} style={{ cursor: 'pointer' }}>
              <MKTypography variant="button" fontWeight="regular" color="dark" textTransform="capitalize" ml={1.5}>
                close
              </MKTypography>
            </div>
          </MKBox>
        </MKBox>
      </Slide>
    </Modal>
  );
}

export default ModalMSM;
