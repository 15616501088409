// @mui icons
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import MessageIcon from "@mui/icons-material/Message";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logo from "assets/images/r-nobg-logo-fullside.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "PT. Multi Sinar Meditron",
    image: logo,
    route: "/",
  },
  socials: [
    {
      icon: <WhatsAppIcon />, 
      link: "https://wa.me/6282348661955",
    },
    {
      icon: <EmailIcon />, 
      link: "mailto:sales.multisinar@gmail.com",
    },
    {
      icon: <PhoneIcon />,
      link: "tel:0411851065",
    },
    {
      icon: <FaxIcon />,
      link: "tel:0411853297",
    },
    {
      icon: <MessageIcon />,
      link: "/contact-us/contacts",
    }
  ],
  address: {
    street: "Jl. Domba No. 43",
    city: "Makassar",
    province: "Sulawesi Selatan",
    state: "Indonesia",
    postalCode: "90142",
  },
  menus: [
    {
      name: "company",
      items: [
        { name: "home", href: "/home" },
        { name: "about us", href: "/info/about-us" },
      ],
    },
    {
      name: "teams",
      items: [
        { name: "company history", href: "/info/history" },
        { name: "teams & career", href: "/career" },
      ],
    },
    {
      name: "products & services",
      items: [
        { name: "products", href: "/products-services/products" },
        { name: "services", href: "/products-services/services" },
      ],
    },
    {
      name: "Help & Support",
      items: [
        { name: "Contact Us", href: "/contact-us/contacts" },
        { name: "FAQ", href: "/contact-us/FAQ" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Company Profile by{" "}
      <MKTypography
        component="a"
        href="#"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        PT. Multi Sinar Meditron
      </MKTypography>
      .
    </MKTypography>
  ),
};
