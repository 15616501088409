/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CenteredBackgroundBlogCard from "examples/Cards/BlogCards/CenteredBackgroundBlogCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bg1 from "assets/images/bg-blue-abs5.jpg";

// Fetching Data - Web Components
import { useState, useEffect } from "react";
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function GoalMSM() {
  const [companyContentData, setCompanyContentData] = useState([]);
  const imageComponents = {
    bg1,
  };

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}company/content`)
      .then((response) => {
        const allData = response.data;
        // Filter data berdasarkan tipe konten
        const filteredData = allData.filter((item) => {
          return item.contentType === "visi" || item.contentType === "misi" || item.contentType === "value";
        });
        setCompanyContentData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching company content data:', error);
      });
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MKBox component="section" py={12}>
      <Container>
      <Grid container spacing={2} mt={10}>
        <Grid item xs={12} lg={5} sx={{ mt: { xs: 3, lg: 0 }, transformStyle: 'preserve-3d', perspective: '1000px' }}>
          <MKBox
            className="flippable-card"
            sx={{
              width: '100%',
              transformStyle: 'preserve-3d',
              transition: 'transform 0.5s',
              position: 'relative',
              '&:hover': {
                transform: 'rotateY(180deg)',
              },
            }}
          >
            <MKBox
              className="card front"
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                backfaceVisibility: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '16px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', 
                transform: 'rotateY(0deg)',
              }}
            >
              {companyContentData
              .filter((contentItem) => contentItem.contentType === "visi")
              .map((contentItem, index) => {
                const ImageComponent = imageComponents[contentItem.others];
                return (
                  <CenteredBackgroundBlogCard
                    image={ImageComponent}
                    type="filled"
                    title={contentItem.contentDesc}
                    label={contentItem.contentTitle}
                  />
                )
              })}
            </MKBox>
            <MKBox
              className="card back"
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                backfaceVisibility: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '16px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                transform: 'rotateY(180deg)',
              }}
            >
              {companyContentData
              .filter((contentItem) => contentItem.contentType === "misi")
              .map((contentItem, index) => (
                <CenteredBackgroundBlogCard
                  type="not-filled"
                  title={contentItem.contentDesc}
                  label={contentItem.contentTitle}
                />
              ))}
            </MKBox>
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 27.5, lg: -17.5 } }}>
          <MKTypography variant="h4" mb={4} mt={3}>Kami berkomitmen untuk menanamkan nilai...</MKTypography>
          <Grid container justifyContent="flex-start">
            {companyContentData
            .filter((contentItem) => contentItem.contentType === "value")
            .map((contentItem, index) => (
              <Grid item xs={12} md={6}>
                <MKBox mb={3}>
                  <DefaultInfoCard
                    icon={contentItem.others}
                    title={contentItem.contentTitle}
                    description={contentItem.contentDesc}
                    color="info"
                  />
                </MKBox>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </MKBox>
  );
}

export default GoalMSM;
