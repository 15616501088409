/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { styled } from '@mui/system';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Kit 2 React components
import MKTypography from 'components/MKTypography';

const pastelColor1 = '#f2f7fc';
const pastelColor2 = '#f5f5fa';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: pastelColor1,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(0),
  '&:hover': {
    boxShadow: `0px 4px 8px ${theme.palette.action.hoverShadow}`,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.contrastText,
  borderBottom: `0.5px solid ${theme.palette.divider}`,
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(1),
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: pastelColor2,
}));

function VacancyItem({ title, desc, req, expanded, onChange }) {
  // Memisahkan jobDesc dan jobReq menjadi poin-poin
  const jobDescPoints = desc.split('\n');
  const jobReqPoints = req.split('\n');

  return (
    <StyledAccordion expanded={expanded} onChange={onChange}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="vacancy-content"
        id="vacancy-header"
      >
        <MKTypography variant={expanded ? 'h5' : 'h6'} color="dark">
          {title}
        </MKTypography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <MKTypography variant="h6" mb={1}>
          Job Description:
          <MKTypography variant="body2" ml={4}>
            <ul>
              {jobDescPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </MKTypography>
        </MKTypography>
        <MKTypography variant="h6" mb={1}>
          Requirement:
          <MKTypography variant="body2" ml={4}>
            <ul>
              {jobReqPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </MKTypography>
        </MKTypography>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}


export default VacancyItem;
