/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// FAQ page sections
import FaqItemMSM from "./sections/FaqItem";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Fetching Data - Web Components
import { useState, useEffect } from 'react';
import { API_SERVER } from 'config/constant';
import Axios from 'axios';

function FaqMSM({}) {
  const [faqData, setFaqData] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Function to fetch data
  const fetchData = () => {
    Axios.get(`${API_SERVER}faq`)
      .then((response) => {
        // Filter FAQs with status === true
        const filteredData = response.data.filter((faqItem) => faqItem.status === true);
        setFaqData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching FAQ data:', error);
      });
  };

  // Fetchig out the data every 5 minutes
  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds
    return () => {
      clearInterval(intervalId);
    };
  }, []); 

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://wa.me/6282348661955",
            label: "Contact Now!",
            color: "success",
          }}
          sticky
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid
          item
          ml={{ xs: "auto", lg: 10 }}
          mr={{ xs: "auto", lg: 10 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MKTypography variant="h3" color="white" >
                Frequently Asked Questions (FAQ)
              </MKTypography>
            </MKBox>
            <MKBox mt={2} mb={3} ml={3} mr={3}>
              {/* Use the FaqItem component for each FAQ entry */}
              {faqData.map((faqItem, index) => (
                <FaqItemMSM
                  key={index}
                  question={faqItem.question}
                  answer={faqItem.answer}
                  expanded={expanded === `panel${index + 1}`}
                  onChange={handleAccordionChange(`panel${index + 1}`)}
                />
              ))}
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default FaqMSM;