/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Company Pages
import WelcomingPageMSM from "company-pages/WelcomingPage";
import HomePageMSM from "company-pages/Presentation";
import AboutUsMSM from "company-pages/AbousUsPage";
import HistoryMSM from "company-pages/HistoryPage";
import TeamsMSM from "company-pages/TeamsPage";
import ProductsMSM from "company-pages/ProductsPage";
import ServicesMSM from "company-pages/ServicesPage";
import ContactUsMSM from "company-pages/ContactUsPage";
import FaqMSM from "company-pages/FAQPage";

const routes = [
  {
    route: "/",
    component: <WelcomingPageMSM />,
    protected: false,
  },
  {
    name: "Home",
    icon: <Icon>home</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    route: "/home",
    component: <HomePageMSM />,
    protected: false,
  },
  {
    name: "Info",
    icon: <Icon>business</Icon>,
    collapse: [
      {
        name: "Company Profile",
        description: "See more about Multi Sinar",
        dropdown: true,
        collapse: [
          {
            name: "About Us",
            route: "/info/about-us",
            component: <AboutUsMSM />,
            protected: false,
          },
          {
            name: "Company History",
            route: "/info/history",
            component: <HistoryMSM />,
            protected: false,
          },
        ],
      },
      {
        name: "Teams & Career",
        description: "See more about our teams",
        dropdown: false,
        route: "/career",
        component: <TeamsMSM />,
        protected: false,
      },
    ],
  },
  {
    name: "Products & Services",
    icon: <Icon>shopping_cart</Icon>,
    collapse: [
      {
        name: "Products",
        description: "Discover more our products",
        dropdown: false,
        route: "/products-services/products",
        component: <ProductsMSM />,
        protected: false,
      },
      {
        name: "Services",
        description: "Discover more our services",
        dropdown: false,
        route: "/products-services/services",
        component: <ServicesMSM />,
        protected: false,
      },
    ],
  },
  {
    name: "Contact Us",
    icon: <Icon>contact_support</Icon>,
    collapse: [
      {
        name: "Contacts",
        description: "Reach Us!",
        dropdown: false,
        route: "/contact-us/contacts",
        component: <ContactUsMSM />,
        protected: false,
      },
      {
        name: "FAQ",
        description: "Explore more!",
        dropdown: false,
        route: "/contact-us/FAQ",
        component: <FaqMSM />,
        protected: false,
      },
    ],
  },
  // {
  //   name: "sections",
  //   icon: <Icon>view_day</Icon>,
  //   collapse: [
  //     {
  //       name: "page sections",
  //       description: "See all sections",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "page headers",
  //           route: "/sections/page-sections/page-headers",
  //           component: <PageHeaders />,
  //           protected: false,
  //         },
  //         {
  //           name: "features",
  //           route: "/sections/page-sections/features",
  //           component: <Features />,
  //           protected: false,
  //         },
  //       ],
  //     },
  //     {
  //       name: "navigation",
  //       description: "See all navigations",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "navbars",
  //           route: "/sections/navigation/navbars",
  //           component: <Navbars />,
  //           protected: false,
  //         },
  //         {
  //           name: "nav tabs",
  //           route: "/sections/navigation/nav-tabs",
  //           component: <NavTabs />,
  //           protected: false,
  //         },
  //         {
  //           name: "pagination",
  //           route: "/sections/navigation/pagination",
  //           component: <Pagination />,
  //           protected: false,
  //         },
  //       ],
  //     },
      // {
      //   name: "input areas",
      //   description: "See all input areas",
      //   dropdown: true,
      //   collapse: [
      //     {
      //       name: "inputs",
      //       route: "/sections/input-areas/inputs",
      //       component: <Inputs />,
      //       protected: false,
      //     },
      //     {
      //       name: "forms",
      //       route: "/sections/input-areas/forms",
      //       component: <Forms />,
      //       protected: false,
      //     },
      //   ],
      // },
  //     {
  //       name: "attention catchers",
  //       description: "See all examples",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "alerts",
  //           route: "/sections/attention-catchers/alerts",
  //           component: <Alerts />,
  //           protected: false,
  //         },
  //         {
  //           name: "modals",
  //           route: "/sections/attention-catchers/modals",
  //           component: <Modals />,
  //           protected: false,
  //         },
  //         {
  //           name: "tooltips & popovers",
  //           route: "/sections/attention-catchers/tooltips-popovers",
  //           component: <TooltipsPopovers />,
  //           protected: false,
  //         },
  //       ],
  //     },
  //     {
  //       name: "elements",
  //       description: "See all 32 examples",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "avatars",
  //           route: "/sections/elements/avatars",
  //           component: <Avatars />,
  //           protected: false,
  //         },
  //         {
  //           name: "badges",
  //           route: "/sections/elements/badges",
  //           component: <Badges />,
  //           protected: false,
  //         },
  //         {
  //           name: "breadcrumbs",
  //           route: "/sections/elements/breadcrumbs",
  //           component: <BreadcrumbsEl />,
  //           protected: false,
  //         },
  //         {
  //           name: "buttons",
  //           route: "/sections/elements/buttons",
  //           component: <Buttons />,
  //           protected: false,
  //         },
  //         {
  //           name: "dropdowns",
  //           route: "/sections/elements/dropdowns",
  //           component: <Dropdowns />,
  //           protected: false,
  //         },
  //         {
  //           name: "progress bars",
  //           route: "/sections/elements/progress-bars",
  //           component: <ProgressBars />,
  //           protected: false,
  //         },
  //         {
  //           name: "toggles",
  //           route: "/sections/elements/toggles",
  //           component: <Toggles />,
  //           protected: false,
  //         },
  //         {
  //           name: "typography",
  //           route: "/sections/elements/typography",
  //           component: <Typography />,
  //           protected: false,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes;
